var EsKitFormResolver = /** @class */ (function () {
    function EsKitFormResolver(formHelperService) {
        var _this = this;
        this.formHelperService = formHelperService;
        this.resolve = function (form) {
            if (!form)
                return undefined;
            var productKitCode = _this.formHelperService.getValueAsStringFromForm(form, EsKitFormResolver.productKitCode);
            var tariffUuid = _this.formHelperService.getValueAsStringFromForm(form, EsKitFormResolver.tariffUuid);
            return {
                productKitCode: productKitCode !== null && productKitCode !== void 0 ? productKitCode : undefined,
                tariffUuid: tariffUuid !== null && tariffUuid !== void 0 ? tariffUuid : undefined,
            };
        };
    }
    EsKitFormResolver.productKitCode = 'productKitCode';
    EsKitFormResolver.tariffUuid = 'tariffUuid';
    return EsKitFormResolver;
}());
export { EsKitFormResolver };
