var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { ESkyengTildaFormState } from '@app/tilda-integration/interfaces/form.interface';
import { FormHelperService as SharedFormHelperService } from '@shared/services/form-helper.service';
var FormHelperService = /** @class */ (function (_super) {
    __extends(FormHelperService, _super);
    function FormHelperService(jqueryFormResolver) {
        var _this = _super.call(this) || this;
        _this.jqueryFormResolver = jqueryFormResolver;
        _this.lockingIntervals = {};
        _this._permanentHideSuccessBox = function ($form) {
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            var successBox = form.querySelector('.js-successbox');
            if (successBox) {
                successBox.style.display = 'none';
            }
        };
        _this._saveOptionsInElement = function ($form, options) {
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            if (!options || typeof options !== 'object')
                return;
            for (var _i = 0, _a = Object.entries(options); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                form.setAttribute("data-skyeng-option-".concat(key), value);
            }
        };
        _this.checkLockingForm = function (form) {
            var _a;
            var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'unknown';
            clearInterval(_this.lockingIntervals[formId]);
            var counter = 0;
            var check = function () {
                if (document.readyState === 'complete'
                    && _this.isTildaPhoneMaskScriptLoaded()
                    && form.getAttribute('data-form-state') !== ESkyengTildaFormState.LOCKED) {
                    clearInterval(_this.lockingIntervals[formId]);
                    _this.unlockForm(form);
                }
                else {
                    _this.lockForm(form);
                }
                counter += 500;
                if (counter > 10000) {
                    clearInterval(_this.lockingIntervals[formId]);
                }
            };
            _this.lockingIntervals[formId] = window.setInterval(check, 500);
            check();
        };
        _this.setFormState = function (state, form, formData) {
            var _a;
            switch (state) {
                case ESkyengTildaFormState.LOCKED:
                    form.setAttribute('data-form-state', 'locked');
                    _this.lockForm(form);
                    break;
                case ESkyengTildaFormState.FILLING:
                    form.setAttribute('data-form-state', 'filling');
                    _this.unlockForm(form);
                    (_a = window.tildaForm) === null || _a === void 0 ? void 0 : _a.closeSuccessPopup();
                    _this.restoreFormInputValues(form, formData !== null && formData !== void 0 ? formData : new FormData());
                    break;
                default:
                    break;
            }
        };
        _this.unlockForm = function (form) {
            _this.enableSubmitButtonInForm(form);
            _this.hideSubmitButtonLoaderInForm(form);
        };
        _this.lockForm = function (form) {
            _this.disableSubmitButtonInForm(form);
            _this.showSubmitButtonLoaderInForm(form);
        };
        _this.restoreFormInputValues = function ($form, formData) {
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            formData.forEach(function (value, key) {
                try {
                    var input = form.querySelector("input[name=\"".concat(key, "\"]"));
                    if (input && typeof value === 'string') {
                        input.value = value;
                    }
                }
                catch (error) {
                    console.warn('#restoreFormInputValues: ', error);
                }
            });
        };
        return _this;
    }
    /**
     * Example: '#rec416655990, #rec416656007' => '#rec416655990 .js-form-proccess, #rec416656007 .js-form-proccess'
     * */
    FormHelperService.prototype.getTildaFormMultipleSelectorFromWrapSelector = function (idSelectors, nestedFormSelector) {
        if (nestedFormSelector === void 0) { nestedFormSelector = '.js-form-proccess'; }
        if (!idSelectors)
            return '';
        return idSelectors.split(',').map(function (selector) { return selector + " ".concat(nestedFormSelector); }).join(', ');
    };
    FormHelperService.prototype.isTildaPhoneMaskScriptLoaded = function () {
        return !document.querySelector('.js-phonemask-input');
    };
    FormHelperService.prototype.showSubmitButtonLoaderInForm = function (form) {
        var _a;
        (_a = form.querySelector('button[type="submit"]')) === null || _a === void 0 ? void 0 : _a.classList.add('t-btn_sending');
    };
    FormHelperService.prototype.hideSubmitButtonLoaderInForm = function (form) {
        var _a;
        (_a = form.querySelector('button[type="submit"]')) === null || _a === void 0 ? void 0 : _a.classList.remove('t-btn_sending');
    };
    FormHelperService.INPUT_BOX_SELECTOR = '.t-form__inputsbox';
    return FormHelperService;
}(SharedFormHelperService));
export { FormHelperService };
