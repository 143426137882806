/* eslint-disable max-len */
/**
 * Info about locale and serviceLocale https://confluence.skyeng.tech/pages/viewpage.action?pageId=75239668
 *
 * Lists are here https://gitlab.skyeng.link/skyeng/id/-/blob/master/config/services.yaml#L177-179
 *
 * Id has endpoints http://id.skyeng.ru/api/v1/util/locales and http://id.skyeng.ru/api/v1/util/serviceLocales
 * to return the lists, but they require authorization
 * */
var LocaleService = /** @class */ (function () {
    function LocaleService() {
    }
    LocaleService.prototype.getAvailableLocale = function (customLang) {
        return this._getAvailableLocale(customLang, LocaleService.localeAvailableList, LocaleService.defaultFallbackLang);
    };
    LocaleService.prototype.getAvailableServiceLocale = function (customLang) {
        return this._getAvailableLocale(customLang, LocaleService.serviceLocaleAvailableList, LocaleService.defaultFallbackLang);
    };
    LocaleService.prototype.getAvailableSkyengschoolServiceLocale = function (customLang) {
        return this._getAvailableLocale(customLang, LocaleService.skyengschoolServiceLocales, LocaleService.defaultSkyengschoolFallbackLang);
    };
    LocaleService.prototype._getAvailableLocale = function (customLang, list, fallbackLang) {
        var _a;
        var resultLang = customLang;
        if (!resultLang) {
            resultLang = (_a = window.navigator) === null || _a === void 0 ? void 0 : _a.language.split('-')[0];
        }
        return list.includes(resultLang) ? resultLang : fallbackLang;
    };
    LocaleService.localeAvailableList = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'ms', 'pl', 'pt', 'ru', 'th', 'tr', 'uk'];
    LocaleService.serviceLocaleAvailableList = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'ms', 'pl', 'pt', 'ru', 'th', 'tr', 'uk'];
    LocaleService.skyengschoolServiceLocales = ['ar', 'de', 'en', 'es', 'fr', 'it', 'ja', 'ko', 'ms', 'pl', 'pt', 'th', 'tr'];
    LocaleService.defaultFallbackLang = 'ru';
    LocaleService.defaultSkyengschoolFallbackLang = 'en';
    return LocaleService;
}());
export { LocaleService };
