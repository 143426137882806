var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { EFormAnalyticsInViewportEvent, EFormAnalyticsSentFailEvent, EFormAnalyticsSentSuccessEvent, EFormAnalyticsStartInteractionEvent, EFormAnalyticsSubmitEvent, } from '@shared/interfaces/analytics.interface';
var FormAnalyticsService = /** @class */ (function () {
    function FormAnalyticsService(dataLayerEventService) {
        var _this = this;
        this.dataLayerEventService = dataLayerEventService;
        this.sendShowEvent = function (eventName, formId, esKitFields) {
            var event = {
                event: eventName,
                formId: formId,
            };
            if (eventName === EFormAnalyticsInViewportEvent.ORDERS) {
                event = __assign(__assign({}, event), { stk: undefined, isBump: undefined, errorMessage: undefined });
            }
            /* Add es-kit properties if orders */
            if (eventName === EFormAnalyticsInViewportEvent.ORDERS && esKitFields) {
                event = __assign(__assign({}, event), { productKitCode: esKitFields.productKitCode, tariffUuid: esKitFields.tariffUuid });
            }
            _this.dataLayerEventService.pushTag(event);
        };
        this.sendInteractionEvent = function (eventName, formId, esKitFields) {
            var event = {
                event: eventName,
                formId: formId,
            };
            if (eventName === EFormAnalyticsStartInteractionEvent.ORDERS) {
                event = __assign(__assign({}, event), { stk: undefined, isBump: undefined, errorMessage: undefined });
            }
            /* Add es-kit properties if orders */
            if (eventName === EFormAnalyticsStartInteractionEvent.ORDERS && esKitFields) {
                event = __assign(__assign({}, event), { productKitCode: esKitFields.productKitCode, tariffUuid: esKitFields.tariffUuid });
            }
            _this.dataLayerEventService.pushTag(event);
        };
        this.sendSubmitEvent = function (eventName, formId, stk, esKitFields, subscribeTo) {
            var event = {
                event: eventName,
                formId: formId,
            };
            if (eventName === EFormAnalyticsSubmitEvent.ORDERS) {
                event = __assign(__assign({}, event), { stk: stk, isBump: undefined, errorMessage: undefined });
            }
            /* Add es-kit properties if orders */
            if (eventName === EFormAnalyticsSubmitEvent.ORDERS && esKitFields) {
                event = __assign(__assign({}, event), { productKitCode: esKitFields.productKitCode, tariffUuid: esKitFields.tariffUuid });
            }
            /* Add subscribe_to property if leads */
            if (eventName === EFormAnalyticsSubmitEvent.LEADS) {
                event = __assign(__assign({}, event), { subscribe_to: subscribeTo !== null && subscribeTo !== void 0 ? subscribeTo : '' });
            }
            _this.dataLayerEventService.pushTag(event);
        };
        this.sendSuccessEvent = function (eventName, formId, stk, esKitFields, isBump, subscribeTo, userID, callback) {
            var event = {
                event: eventName,
                formId: formId,
            };
            if (eventName === EFormAnalyticsSentSuccessEvent.ORDERS) {
                event = __assign(__assign({}, event), { stk: stk, userId: userID, errorMessage: undefined });
            }
            /* Add es-kit properties if orders */
            if (eventName === EFormAnalyticsSentSuccessEvent.ORDERS && esKitFields) {
                event = __assign(__assign({}, event), { productKitCode: esKitFields.productKitCode, tariffUuid: esKitFields.tariffUuid });
            }
            /* Add isBump property if orders */
            if (eventName === EFormAnalyticsSentSuccessEvent.ORDERS) {
                event = __assign(__assign({}, event), { isBump: isBump === undefined ? 'false' : isBump.toString() });
            }
            /* Add subscribe_to property if leads */
            if (eventName === EFormAnalyticsSentSuccessEvent.LEADS) {
                event = __assign(__assign({}, event), { subscribe_to: subscribeTo !== null && subscribeTo !== void 0 ? subscribeTo : '' });
            }
            if (callback) {
                event = __assign(__assign({}, event), { eventCallback: callback, eventTimeout: 2000 });
            }
            var hasEventBeenSent = _this.dataLayerEventService.pushTag(event);
            if (!hasEventBeenSent && callback) {
                callback();
            }
        };
        this.sendFailEvent = function (eventName, formId, errorMessage, stk, esKitFields, subscribeTo) {
            var event = {
                event: eventName,
                formId: formId,
            };
            if (eventName === EFormAnalyticsSentFailEvent.LEADS) {
                event = __assign(__assign({}, event), { error_message: errorMessage });
            }
            if (eventName === EFormAnalyticsSentFailEvent.ORDERS) {
                event = __assign(__assign({}, event), { stk: stk, isBump: undefined, errorMessage: errorMessage });
            }
            /* Add es-kit properties if orders */
            if (eventName === EFormAnalyticsSentFailEvent.ORDERS && esKitFields) {
                event = __assign(__assign({}, event), { productKitCode: esKitFields.productKitCode, tariffUuid: esKitFields.tariffUuid });
            }
            /* Add subscribe_to property if leads */
            if (eventName === EFormAnalyticsSentFailEvent.LEADS) {
                event = __assign(__assign({}, event), { subscribe_to: subscribeTo !== null && subscribeTo !== void 0 ? subscribeTo : '' });
            }
            _this.dataLayerEventService.pushTag(event);
        };
        this.sendUserEvent = function (userId) {
            var event = { 'event': 'userData', userId: userId };
            _this.dataLayerEventService.pushTag(event);
        };
    }
    return FormAnalyticsService;
}());
export { FormAnalyticsService };
