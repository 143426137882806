var JqueryDataGetResolver = /** @class */ (function () {
    function JqueryDataGetResolver(tildaFormJqueryDependentWrapResolver) {
        this.tildaFormJqueryDependentWrapResolver = tildaFormJqueryDependentWrapResolver;
    }
    /**
     * .data() function in Jquery is not an accessor to HTML data attributes,
     * it some sort of caching, be aware
     *
     * More about the issue here https://stackoverflow.com/a/6828180/12272852
     * */
    JqueryDataGetResolver.prototype.resolve = function (form, dataAttributeName) {
        if (!form)
            return '';
        return this.tildaFormJqueryDependentWrapResolver.resolve(function () { var _a; return (_a = form.getAttribute("data-".concat(dataAttributeName))) !== null && _a !== void 0 ? _a : ''; }, function () { return $(form).data(dataAttributeName); });
    };
    return JqueryDataGetResolver;
}());
export { JqueryDataGetResolver };
