var DataLayerEventService = /** @class */ (function () {
    function DataLayerEventService(debugService) {
        var _this = this;
        this.debugService = debugService;
        this.pushTag = function (item) {
            if (!(window.dataLayer instanceof Array)) {
                console.warn("DataLayerEventService: dataLayer is not available. \"".concat(item.event, "\" event has fired but has not been sent"));
                _this.debugService.warn(item);
                return false;
            }
            window.dataLayer.push(item);
            _this.debugService.log(item);
            return true;
        };
    }
    return DataLayerEventService;
}());
export { DataLayerEventService };
