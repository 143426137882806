export var ECrmErrorKey;
(function (ECrmErrorKey) {
    ECrmErrorKey["USER_ALREADY_EXISTS"] = "user_already_exists";
    ECrmErrorKey["EMAIL_ALREADY_USED"] = "email_already_used";
    ECrmErrorKey["EMAIL_ALREADY_USED_IN_ANOTHER_PRODUCT"] = "email_already_used_in_another_product";
    ECrmErrorKey["EMAIL_OR_PHONE_ALREADY_IN_USE"] = "email_or_phone_already_in_use";
    ECrmErrorKey["EMAIL_ALREADY_USED_IN_ANOTHER_PRODUCT_LOGIN_OR_USE_ANOTHER_EMAIL"] = "email_already_used_in_another_product_login_or_use_another_email";
    ECrmErrorKey["PROVIDE_EMAIL"] = "validation.provide_email";
    ECrmErrorKey["INVALID_EMAIL"] = "invalid_email";
    ECrmErrorKey["INVALID_TIMEZON"] = "invalid_timezon";
    ECrmErrorKey["INVALID_PHONE_NUMBER"] = "invalid_phone_number";
    ECrmErrorKey["EMAIL_SERVER_NOT_FOUND"] = "email_server_not_found";
    ECrmErrorKey["PROVIDE_PHONE_NUMBER"] = "provide_phone_number";
    ECrmErrorKey["PROVIDE_NAME"] = "provide_name";
    ECrmErrorKey["NAME_MUST_NOT_HAVE_NUMBERS"] = "name_must_not_have_numbers";
    ECrmErrorKey["INVALID_LANGUAGE"] = "invalid_language";
    ECrmErrorKey["PROVIDE_EMAIL_OR_PHONE_NUMBER"] = "provide_email_or_phone_number";
    ECrmErrorKey["ORDER_ALREADY_EXISTS"] = "order_already_exists";
})(ECrmErrorKey || (ECrmErrorKey = {}));
