import { EFormAnalyticsSentSuccessEvent } from '@shared/interfaces/analytics.interface';
import { TildaFormRepaintEvent } from '@app/tilda-integration/events/tilda-form-repaint.event';
/**
 * Leads Forms Module
 * Allows to send content leads(subscriptions, etc.) from tilda forms
 */
var LeadsFormService = /** @class */ (function () {
    function LeadsFormService(jqueryFormResolver, jqueryDataGetResolver, analyticsWatcherService, formSuccessHandlerService, formHelperService, formTimezoneService, analyticsService, urlService, cookieService, testingService) {
        var _this = this;
        this.jqueryFormResolver = jqueryFormResolver;
        this.jqueryDataGetResolver = jqueryDataGetResolver;
        this.analyticsWatcherService = analyticsWatcherService;
        this.formSuccessHandlerService = formSuccessHandlerService;
        this.formHelperService = formHelperService;
        this.formTimezoneService = formTimezoneService;
        this.analyticsService = analyticsService;
        this.urlService = urlService;
        this.cookieService = cookieService;
        this.testingService = testingService;
        this.customerAttributeToQueryParamMap = {
            customer_attribute_source_type: 'source_type',
            customer_attribute_utm_source: 'utm_source',
            customer_attribute_utm_medium: 'utm_medium',
            customer_attribute_manager: 'manager',
        };
        this.init = function (formSelector, analyticsEnable) {
            formSelector = formSelector || LeadsFormService.DEFAULT_SELECTOR;
            if (analyticsEnable === undefined) {
                analyticsEnable = true;
            }
            if (!analyticsEnable)
                return;
            document.addEventListener(TildaFormRepaintEvent.EVENT_NAME, function () {
                _this.addLeadsHandlers(formSelector);
            });
            _this.addLeadsHandlers(formSelector);
        };
        this.addLeadsHandlers = function (formSelector) {
            _this.upgradeRadioForm();
            _this.saveUtm();
            _this.addSeidInForms();
            _this._initForms(formSelector);
        };
        this._initForms = function (formMultipleSelector) {
            var _a;
            var forms = document.querySelectorAll(formMultipleSelector);
            /**
             * formMultipleSelector may be a selector of form wrap elements. In this case
             * nested forms should be found by formMultipleSelector concatenated with DEFAULT_SELECTOR
             * */
            if (formMultipleSelector !== LeadsFormService.DEFAULT_SELECTOR
                && !((_a = forms.item(0)) === null || _a === void 0 ? void 0 : _a.classList.contains(LeadsFormService.DEFAULT_SELECTOR.substr(1)))) {
                forms = document.querySelectorAll(_this.formHelperService
                    .getTildaFormMultipleSelectorFromWrapSelector(formMultipleSelector, LeadsFormService.DEFAULT_SELECTOR));
            }
            forms.forEach(function (form) {
                var _a;
                // this.formHelperService.checkLockingForm(form);
                _this.formTimezoneService.addTimezoneInputToForm(form);
                var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top';
                /* add success handler */
                _this.formSuccessHandlerService.addHandler(formId, 'window.skyengTildaCustomerIntegration._successHandler');
                _this.analyticsWatcherService._addFormHandlersForSendAnalytics('LEADS', form);
            });
        };
        this._successHandler = function ($form) {
            var _a, _b;
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top';
            var subscribeTo = (_b = form.querySelector('input[name="subscribe_to"]')) === null || _b === void 0 ? void 0 : _b.value;
            _this.analyticsService.sendSuccessEvent(EFormAnalyticsSentSuccessEvent.LEADS, formId, undefined, undefined, undefined, subscribeTo, undefined, function () { return _this.redirectToSuccessUrl(form); });
        };
        this.redirectToSuccessUrl = function ($form) {
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            var successUrl = _this.jqueryDataGetResolver.resolve(form, 'success-url');
            if (!successUrl
                || (_this.testingService.isEnabled()
                    && !confirm("Redirect to ".concat(successUrl, ". Continue?"))))
                return;
            document.location = successUrl;
        };
        this.saveUtm = function () {
            var utmBlock = _this.buildUtmBlockWithHiddenFields();
            _this.appendUtmBlockWithHiddenFields(utmBlock);
        };
        this.upgradeRadioForm = function () {
            _this.updateValuesOnRadioInputs();
        };
        this.buildUtmBlockWithHiddenFields = function () {
            var hiddenBlock = document.createElement('div');
            hiddenBlock.style.display = 'none';
            hiddenBlock.classList.add('hidden-utm');
            for (var _i = 0, _a = Object.entries(_this.customerAttributeToQueryParamMap); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                var paramValue = _this.urlService.getUrlParam(value);
                if (paramValue !== null) {
                    hiddenBlock.insertAdjacentHTML('beforeend', "<input type=\"hidden\" name=\"".concat(key, "\" value=\"").concat(paramValue, "\"/>"));
                }
            }
            return hiddenBlock;
        };
        this.addSeidInForms = function () {
            var _a;
            var seid = (_a = _this.cookieService.getItem('_seid')) === null || _a === void 0 ? void 0 : _a.value;
            if (!seid)
                return;
            var forms = document.querySelectorAll('form');
            forms.forEach(function (form) {
                if (!form.querySelector('input[name="subscribe_to"]')) {
                    return;
                }
                _this.formHelperService.addInputToForm(form, "<input type=\"hidden\" name=\"seid\" value=\"".concat(seid, "\">"), 'seid');
            });
        };
    }
    LeadsFormService.prototype.appendUtmBlockWithHiddenFields = function (utmBlock) {
        var forms = document.querySelectorAll('form');
        forms.forEach(function (form) {
            var hiddenUtmBlock = form.querySelector('.hidden-utm');
            if (hiddenUtmBlock) {
                return;
            }
            form.append(utmBlock);
        });
    };
    LeadsFormService.prototype.updateValuesOnRadioInputs = function () {
        var labels = document.querySelectorAll('.t-radio__wrapper label');
        labels.forEach(function (label) {
            var _a;
            label.style.visibility = 'visible';
            var input = label.querySelector('input');
            if (!input) {
                return;
            }
            var inputValue = input.value;
            var inputValueArray = (_a = inputValue.split('|')) !== null && _a !== void 0 ? _a : [];
            if (inputValueArray.length !== 2) {
                return;
            }
            input.value = inputValueArray[1];
            label.childNodes.forEach(function (childNode) {
                var _a, _b;
                /* 3 means text node */
                if (childNode.nodeType !== 3) {
                    return;
                }
                childNode.textContent = (_b = (_a = childNode.textContent) === null || _a === void 0 ? void 0 : _a.replace(inputValue, inputValueArray[0])) !== null && _b !== void 0 ? _b : null;
            });
        });
    };
    LeadsFormService.DEFAULT_SELECTOR = '.js-form-proccess';
    return LeadsFormService;
}());
export { LeadsFormService };
