var PromocodeService = /** @class */ (function () {
    function PromocodeService(cookieService, urlService, jqueryFormResolver, debugService) {
        var _this = this;
        this.cookieService = cookieService;
        this.urlService = urlService;
        this.jqueryFormResolver = jqueryFormResolver;
        this.debugService = debugService;
        this.getPromocode = function () {
            if (_this.urlService.getUrlParam(PromocodeService.QUERY_PARAM_NAME)) {
                return _this.urlService.getUrlParam(PromocodeService.QUERY_PARAM_NAME);
            }
            var storedCookie = _this.cookieService.getItem(PromocodeService.COOKIE_NAME);
            return storedCookie ? storedCookie.value : null;
        };
        this.pushPromocodeToInput = function ($form, promoCode, inputName, inputSelector, inputAttributeFillingName) {
            var _a;
            if (promoCode === void 0) { promoCode = _this.getPromocode(); }
            if (inputName === void 0) { inputName = PromocodeService.INPUT_NAME; }
            if (inputSelector === void 0) { inputSelector = "input[name=\"".concat(PromocodeService.INPUT_NAME, "\"]"); }
            if (inputAttributeFillingName === void 0) { inputAttributeFillingName = PromocodeService.INPUT_ATTRIBUTE_FILLING_NAME; }
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            if (!promoCode) {
                _this.debugService.warn('PromocodeService: promocode is not found');
                return;
            }
            var input = (_a = form.querySelector(inputSelector)) !== null && _a !== void 0 ? _a : form.querySelector("input[name=\"".concat(inputName, "\"]"));
            if (!input) {
                form.insertAdjacentHTML('beforeend', "<input type=\"hidden\" name=\"".concat(inputName, "\" ").concat(inputAttributeFillingName, "=\"1\" value=\"").concat(promoCode, "\">"));
                return;
            }
            if (input.getAttribute(inputAttributeFillingName) === '1')
                return;
            input.setAttribute(inputAttributeFillingName, '1');
            input.value = promoCode;
        };
        this.setPromoCode = function (promoCode) {
            _this.defaultPromoCode = promoCode;
        };
    }
    PromocodeService.COOKIE_NAME = 'promocode';
    PromocodeService.QUERY_PARAM_NAME = 'promocode';
    PromocodeService.INPUT_NAME = 'promocode';
    PromocodeService.INPUT_ATTRIBUTE_FILLING_NAME = 'data-promocode-filled';
    return PromocodeService;
}());
export { PromocodeService };
