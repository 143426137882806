var FormHelperService = /** @class */ (function () {
    function FormHelperService() {
        var _this = this;
        this.getValueAsStringFromForm = function (form, name) {
            var value = _this.getFormData(form).get(name);
            return typeof value === 'string' ? value : null;
        };
        this.getValueAsFileFromForm = function (form, name) {
            var value = _this.getFormData(form).get(name);
            return value && typeof value === 'object' ? value : null;
        };
        this.addInputToForm = function (form, input, inputAttributeName, insideFormSelector) {
            var _a;
            var existedInput = form.querySelector("input[name=\"".concat(inputAttributeName, "\"]"));
            if (!existedInput) {
                if (insideFormSelector) {
                    (_a = form.querySelector(insideFormSelector)) === null || _a === void 0 ? void 0 : _a.insertAdjacentHTML('beforeend', input);
                }
                else {
                    form.insertAdjacentHTML('beforeend', input);
                }
            }
        };
    }
    FormHelperService.prototype.getValueFromForm = function (formData, name) {
        return formData.get(name);
    };
    FormHelperService.prototype.getFormData = function (form) {
        if (form.tagName === 'FORM') {
            return new FormData(form);
        }
        var nestedForm = form.querySelector('form');
        return new FormData(nestedForm !== null && nestedForm !== void 0 ? nestedForm : undefined);
    };
    FormHelperService.prototype.enableSubmitButtonInForm = function (form) {
        var _a;
        (_a = form.querySelector('button[type="submit"]')) === null || _a === void 0 ? void 0 : _a.removeAttribute('disabled');
    };
    FormHelperService.prototype.disableSubmitButtonInForm = function (form) {
        var _a;
        (_a = form.querySelector('button[type="submit"]')) === null || _a === void 0 ? void 0 : _a.setAttribute('disabled', 'disabled');
    };
    return FormHelperService;
}());
export { FormHelperService };
