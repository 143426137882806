export var ESkyengTildaFormsErrorKey;
(function (ESkyengTildaFormsErrorKey) {
    ESkyengTildaFormsErrorKey["EMAIL_OR_PHONE_ALREADY_EXIST"] = "EMAIL_OR_PHONE_ALREADY_EXIST";
    ESkyengTildaFormsErrorKey["ALREADY_LOGGED_IN"] = "ALREADY_LOGGED_IN";
    ESkyengTildaFormsErrorKey["ALREADY_EXIST_ORDER"] = "ALREADY_EXIST_ORDER";
    ESkyengTildaFormsErrorKey["ALREADY_HAS_CORPORATIVE_ACCOUNT"] = "ALREADY_HAS_CORPORATIVE_ACCOUNT";
    ESkyengTildaFormsErrorKey["INCORRECT_PHONE_NUMBER"] = "INCORRECT_PHONE_NUMBER";
    ESkyengTildaFormsErrorKey["ERROR"] = "ERROR";
    ESkyengTildaFormsErrorKey["INCORRECT_PHONE_NUMBER_RU"] = "\u041D\u0415\u041A\u041E\u0420\u0420\u0415\u041A\u0422\u041D\u042B\u0419_\u041D\u041E\u041C\u0415\u0420_\u0422\u0415\u041B\u0415\u0424\u041E\u041D\u0410";
    ESkyengTildaFormsErrorKey["ERROR_RU"] = "\u041E\u0428\u0418\u0411\u041A\u0410";
    ESkyengTildaFormsErrorKey["UNKNOWN_ERROR"] = "UNKNOWN_ERROR";
})(ESkyengTildaFormsErrorKey || (ESkyengTildaFormsErrorKey = {}));
