export var EFormAnalyticsInViewportEvent;
(function (EFormAnalyticsInViewportEvent) {
    EFormAnalyticsInViewportEvent["ORDERS"] = "_orders_form_in_viewport";
    EFormAnalyticsInViewportEvent["LEADS"] = "_leads_form_in_viewport";
})(EFormAnalyticsInViewportEvent || (EFormAnalyticsInViewportEvent = {}));
export var EFormAnalyticsStartInteractionEvent;
(function (EFormAnalyticsStartInteractionEvent) {
    EFormAnalyticsStartInteractionEvent["ORDERS"] = "_orders_form_start_interaction";
    EFormAnalyticsStartInteractionEvent["LEADS"] = "_leads_form_start_interaction";
})(EFormAnalyticsStartInteractionEvent || (EFormAnalyticsStartInteractionEvent = {}));
export var EFormAnalyticsSubmitEvent;
(function (EFormAnalyticsSubmitEvent) {
    EFormAnalyticsSubmitEvent["ORDERS"] = "_orders_form_submit";
    EFormAnalyticsSubmitEvent["LEADS"] = "_leads_form_submit";
})(EFormAnalyticsSubmitEvent || (EFormAnalyticsSubmitEvent = {}));
export var EFormAnalyticsSentSuccessEvent;
(function (EFormAnalyticsSentSuccessEvent) {
    EFormAnalyticsSentSuccessEvent["ORDERS"] = "_orders_form_sent_success";
    EFormAnalyticsSentSuccessEvent["LEADS"] = "_leads_form_sent_success";
})(EFormAnalyticsSentSuccessEvent || (EFormAnalyticsSentSuccessEvent = {}));
export var EFormAnalyticsSentFailEvent;
(function (EFormAnalyticsSentFailEvent) {
    EFormAnalyticsSentFailEvent["ORDERS"] = "_orders_form_sent_fail";
    EFormAnalyticsSentFailEvent["LEADS"] = "_leads_form_sent_fail";
})(EFormAnalyticsSentFailEvent || (EFormAnalyticsSentFailEvent = {}));
