var EnalableModeService = /** @class */ (function () {
    function EnalableModeService(cookieService, cookieName) {
        var _this = this;
        this.cookieService = cookieService;
        this.cookieName = cookieName;
        this.isEnabled = function () { var _a; return ((_a = _this.cookieService.getItem(_this.cookieName)) === null || _a === void 0 ? void 0 : _a.value) === 'on'; };
        this.enable = function () {
            _this.cookieService.setItem(_this.cookieName, 'on');
        };
        this.disable = function () {
            _this.cookieService.setItem(_this.cookieName, 'off');
        };
    }
    return EnalableModeService;
}());
export { EnalableModeService };
