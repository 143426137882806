var TildaFormRepaintEvent = /** @class */ (function () {
    function TildaFormRepaintEvent() {
        var _this = this;
        this.event = new Event(TildaFormRepaintEvent.EVENT_NAME);
        this.isWatching = false;
        this.watch = function () {
            if (_this.isWatching)
                return;
            _this.isWatching = true;
            _this.interval = window.setInterval(_this.checkForm, 500);
            _this.checkForm();
        };
        this.stopWatching = function () {
            clearInterval(_this.interval);
            _this.isWatching = false;
        };
        this.checkForm = function () {
            var anyForm = document.querySelector('form');
            if (!anyForm)
                return;
            if (!anyForm.getAttribute(TildaFormRepaintEvent.ATTRIBUTE_NAME)) {
                document.dispatchEvent(_this.event);
                anyForm.setAttribute(TildaFormRepaintEvent.ATTRIBUTE_NAME, '☘');
            }
        };
    }
    TildaFormRepaintEvent.EVENT_NAME = 'tildaform:repaint';
    TildaFormRepaintEvent.ATTRIBUTE_NAME = 'tilda-form-repaint-event-anchor';
    return TildaFormRepaintEvent;
}());
export { TildaFormRepaintEvent };
