var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StorageService } from 'app/shared/services/storage/storage.service';
var SessionStorageService = /** @class */ (function (_super) {
    __extends(SessionStorageService, _super);
    function SessionStorageService() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.isAvailable = function () {
            if (_this.isAvailableCache !== undefined) {
                return _this.isAvailableCache;
            }
            try {
                sessionStorage.setItem('testKey', 'testValue');
                if (sessionStorage.getItem('testKey') === 'testValue') {
                    sessionStorage.removeItem('testKey');
                    _this.isAvailableCache = true;
                    return true;
                }
            }
            catch (_a) { }
            _this.isAvailableCache = false;
            console.warn('Session storage is not available');
            return false;
        };
        _this.getAll = function () {
            var items = [];
            if (!_this.isAvailable()) {
                return items;
            }
            for (var i = 0; i < sessionStorage.length; i++) {
                var key = sessionStorage.key(i);
                if (!key) {
                    continue;
                }
                var value = sessionStorage.getItem(key);
                if (!value) {
                    continue;
                }
                items.push({ key: key, value: value });
            }
            return items;
        };
        _this.getItem = function (key) {
            if (!_this.isAvailable()) {
                return null;
            }
            var value = sessionStorage.getItem(key);
            if (!value) {
                return null;
            }
            return { key: key, value: value };
        };
        _this.setItem = function (key, value) {
            if (!_this.isAvailable())
                return;
            sessionStorage.setItem(key, value);
        };
        _this.removeItem = function (key) {
            if (!_this.isAvailable())
                return;
            sessionStorage.removeItem(key);
        };
        return _this;
    }
    return SessionStorageService;
}(StorageService));
export { SessionStorageService };
