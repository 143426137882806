var JqueryDataSetResolver = /** @class */ (function () {
    function JqueryDataSetResolver(tildaFormJqueryDependentWrapResolver) {
        this.tildaFormJqueryDependentWrapResolver = tildaFormJqueryDependentWrapResolver;
    }
    /**
     * .data() function in Jquery is not an accessor to HTML data attributes,
     * it some sort of caching, be aware
     *
     * More about the issue here https://stackoverflow.com/a/6828180/12272852
     * */
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JqueryDataSetResolver.prototype.resolve = function (form, dataAttributeName, dataAttributeCacheValue) {
        if (!form)
            return;
        this.tildaFormJqueryDependentWrapResolver.resolve(function () {
            try {
                form.setAttribute("data-".concat(dataAttributeName), dataAttributeCacheValue);
            }
            catch (_a) { }
        }, function () { return $(form).data(dataAttributeName, dataAttributeCacheValue); });
    };
    return JqueryDataSetResolver;
}());
export { JqueryDataSetResolver };
