var JqueryFormResolver = /** @class */ (function () {
    function JqueryFormResolver() {
    }
    JqueryFormResolver.prototype.resolve = function ($jform) {
        var _a;
        switch (true) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            case !$jform:
                return null;
            case $jform.jquery !== undefined:
                return (_a = $jform.get(0)) !== null && _a !== void 0 ? _a : null;
            case $jform.nodeType !== undefined:
                return $jform;
            default:
                console.warn('#JqueryFormResolver: passed parameter expected ' +
                    'to be type of JQuery<HTMLFormElement> or HTMLFormElement. given: ', $jform);
                return null;
        }
    };
    return JqueryFormResolver;
}());
export { JqueryFormResolver };
