import { SkyengFormTypeEnum } from '@shared/interfaces/form.interface';
import { EFormAnalyticsInViewportEvent, EFormAnalyticsSentFailEvent, EFormAnalyticsStartInteractionEvent, EFormAnalyticsSubmitEvent, } from '@shared/interfaces/analytics.interface';
var AnalyticsWatcherService = /** @class */ (function () {
    function AnalyticsWatcherService(commonService, errorService, analyticsService, stkResolver, esKitResolver, jqueryDataGetResolver, jqueryDataSetResolver) {
        var _this = this;
        this.commonService = commonService;
        this.errorService = errorService;
        this.analyticsService = analyticsService;
        this.stkResolver = stkResolver;
        this.esKitResolver = esKitResolver;
        this.jqueryDataGetResolver = jqueryDataGetResolver;
        this.jqueryDataSetResolver = jqueryDataSetResolver;
        this.alreadyViewedForms = {
            ORDERS: [],
            LEADS: [],
        };
        this._addFormHandlersForSendAnalytics = function (eventType, form, formType) {
            var _a;
            var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top';
            if ((eventType === 'ORDERS' && _this.jqueryDataGetResolver.resolve(form, 'has-callbacks') === '1')
                || (eventType === 'LEADS' && _this.jqueryDataGetResolver.resolve(form, 'has-content-form-callbacks') === '1'))
                return;
            /* add form show handler */
            _this.watchFormShowAnalyticsEvent(eventType, form, formId, formType);
            /* add form start interaction handler */
            _this.watchFormInteractionAnalyticsEvent(eventType, form, formId, formType);
            /* add form submit handler */
            _this.watchFormSubmitAnalyticsEvent(eventType, form, formId, formType);
            /* add form client validation handler */
            _this.watchFormFailAnalyticsEvent(eventType, form, formId, formType);
            _this.jqueryDataSetResolver.resolve(form, 'has-callbacks', '1');
        };
        this.watchFormShowAnalyticsEvent = function (eventType, form, formId, formType) {
            window.addEventListener('scroll', function () {
                _this._checkFormShowed(eventType, form, formId, formType);
            });
            _this._checkFormShowed(eventType, form, formId, formType);
        };
        this._checkFormShowed = function (eventType, form, formId, formType) {
            if (_this.alreadyViewedForms[eventType].includes(formId))
                return;
            if (!_this.commonService.isElementVisible('#' + formId))
                return;
            var esKitFields = eventType === 'ORDERS'
                && (formType === SkyengFormTypeEnum.ES_KIT_ADULTS || formType === SkyengFormTypeEnum.ES_KIT_KIDS)
                ? _this.esKitResolver.resolve(form)
                : undefined;
            _this.analyticsService.sendShowEvent(EFormAnalyticsInViewportEvent[eventType], formId, esKitFields);
            _this.alreadyViewedForms[eventType].push(formId);
        };
        this.watchFormInteractionAnalyticsEvent = function (eventType, form, formId, formType) {
            var clickHandler = function () {
                var esKitFields = eventType === 'ORDERS'
                    && (formType === SkyengFormTypeEnum.ES_KIT_ADULTS || formType === SkyengFormTypeEnum.ES_KIT_KIDS)
                    ? _this.esKitResolver.resolve(form)
                    : undefined;
                _this.analyticsService.sendInteractionEvent(EFormAnalyticsStartInteractionEvent[eventType], formId, esKitFields);
                form.removeEventListener('click', clickHandler);
            };
            form.addEventListener('click', clickHandler);
        };
        this.watchFormSubmitAnalyticsEvent = function (eventType, form, formId, formType) {
            var submitButton = form.querySelector('[type=submit]');
            var submitHandler = function () {
                var _a;
                var formStk = eventType === 'ORDERS'
                    && (formType === SkyengFormTypeEnum.ADULTS || formType === SkyengFormTypeEnum.KIDS)
                    ? _this.stkResolver.resolve(form, formType)
                    : undefined;
                var esKitFields = formStk === undefined ? _this.esKitResolver.resolve(form) : undefined;
                var subscribeTo = eventType === 'LEADS'
                    ? (_a = form.querySelector('input[name="subscribe_to"]')) === null || _a === void 0 ? void 0 : _a.value
                    : undefined;
                _this.analyticsService.sendSubmitEvent(EFormAnalyticsSubmitEvent[eventType], formId, formStk, esKitFields, subscribeTo);
            };
            submitButton === null || submitButton === void 0 ? void 0 : submitButton.addEventListener('click', submitHandler);
        };
        this.watchFormFailAnalyticsEvent = function (eventType, form, formId, formType) {
            var failHandler = function () {
                var _a;
                if (_this.jqueryDataGetResolver.resolve(form, 'is-backend-validation-error') === '1') {
                    _this.jqueryDataSetResolver.resolve(form, 'is-backend-validation-error', '0');
                    return;
                }
                var formStk = eventType === 'ORDERS'
                    && (formType === SkyengFormTypeEnum.ADULTS || formType === SkyengFormTypeEnum.KIDS)
                    ? _this.stkResolver.resolve(form, formType)
                    : undefined;
                var esKitFields = formStk === undefined ? _this.esKitResolver.resolve(form) : undefined;
                var subscribeTo = eventType === 'LEADS'
                    ? (_a = form.querySelector('input[name="subscribe_to"]')) === null || _a === void 0 ? void 0 : _a.value
                    : undefined;
                var formError = _this.errorService.getClientValidationError(form);
                _this.analyticsService.sendFailEvent(EFormAnalyticsSentFailEvent[eventType], formId, formError, formStk, esKitFields, subscribeTo);
            };
            form.addEventListener('tildaform:aftererror', failHandler);
        };
    }
    return AnalyticsWatcherService;
}());
export { AnalyticsWatcherService };
