export var EProductSubject;
(function (EProductSubject) {
    EProductSubject["ENGLISH"] = "english";
    EProductSubject["MATHEMATICS"] = "mathematics";
    EProductSubject["PHYSICS"] = "physics";
    EProductSubject["RUSSIAN"] = "russian";
    EProductSubject["SOCIALSCIENCE"] = "socialScience";
    EProductSubject["CHESS"] = "chess";
    EProductSubject["PRESCHOOL"] = "preschool";
    EProductSubject["TALKS"] = "talks,";
    EProductSubject["DRAWING"] = "drawing";
    EProductSubject["LIFE"] = "life";
    EProductSubject["COMPUTERSCIENCE"] = "computerScience";
    // lc specific subjects
    EProductSubject["HISTORY"] = "history";
    EProductSubject["LITERATURE"] = "literature";
    EProductSubject["TRENDCOURSES"] = "trendCourses";
    EProductSubject["CHEMISTRY"] = "chemistry";
    EProductSubject["GEOGRAPHY"] = "geography";
    EProductSubject["BIOLOGY"] = "biology";
    EProductSubject["MNEMONICS"] = "mnemonics";
    EProductSubject["GEOMETRY"] = "geometry";
    EProductSubject["VIDEOBLOGGING"] = "videoBlogging";
    EProductSubject["ALGEBRA"] = "algebra";
})(EProductSubject || (EProductSubject = {}));
