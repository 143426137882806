import { ECrmErrorKey } from '@shared/interfaces/crm-error-key.enum';
export var CRM_NAME_RELATED_ERROR_KEYS = [
    ECrmErrorKey.PROVIDE_NAME,
    ECrmErrorKey.NAME_MUST_NOT_HAVE_NUMBERS,
];
export var CRM_EMAIL_RELATED_ERROR_KEYS = [
    ECrmErrorKey.EMAIL_ALREADY_USED,
    ECrmErrorKey.EMAIL_ALREADY_USED_IN_ANOTHER_PRODUCT,
    ECrmErrorKey.EMAIL_ALREADY_USED_IN_ANOTHER_PRODUCT_LOGIN_OR_USE_ANOTHER_EMAIL,
    ECrmErrorKey.PROVIDE_EMAIL,
    ECrmErrorKey.INVALID_EMAIL,
];
export var CRM_PHONE_RELATED_ERROR_KEYS = [
    ECrmErrorKey.INVALID_PHONE_NUMBER,
    ECrmErrorKey.PROVIDE_PHONE_NUMBER,
];
