var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
var _a;
import { ESkyengTildaFormsErrorKey, } from '@app/tilda-integration/interfaces/error.interface';
var ErrorService = /** @class */ (function () {
    function ErrorService(jqueryFormResolver, tildaFormErroredInputResolver) {
        var _this = this;
        this.jqueryFormResolver = jqueryFormResolver;
        this.tildaFormErroredInputResolver = tildaFormErroredInputResolver;
        this.errorsTranslationDictionary = new Map(ErrorService.errorsTranslationDictionaryDefault);
        this.onErrorCallbacks = [];
        this.currentError = '';
        this.watchPageLoad = function () {
            if (document.readyState === 'complete') {
                _this.onWindowLoad();
            }
            else {
                window.addEventListener('load', _this.onWindowLoad);
            }
        };
        this.onWindowLoad = function () {
            _this.proxyingTildaFormDictionary();
            window.removeEventListener('load', _this.onWindowLoad);
        };
        this.proxyingTildaFormDictionary = function () {
            /**
             * Proxying original "t_form_dict" function to add "custom" error type handler
             */
            if (typeof window.t_form_dict === 'function') {
                window.t_form_dict_original = window.t_form_dict;
                window.t_form_dict = function (errorType) {
                    if (errorType === 'custom') {
                        return _this.currentError;
                    }
                    return window.t_form_dict_original(errorType);
                };
            }
            else {
                console.warn('window.t_form_dict is not defined. Custom errors localization will not work. ' +
                    'Make sure "tilda-forms" module is included');
            }
        };
        this.addTranslationsToErrorsTranslationDictionary = function (newTranslations) {
            if (newTranslations === void 0) { newTranslations = []; }
            if (!_this.validateTranslationsArray(newTranslations)) {
                console.warn('#TildaIntegrationErrorsService: new error translations are invalid', newTranslations);
                return;
            }
            _this.errorsTranslationDictionary = new Map(__spreadArray(__spreadArray([], Array.from(ErrorService.errorsTranslationDictionaryDefault), true), newTranslations, true));
        };
        this.getLocatedErrorText = function (errorKey, language) {
            var _a;
            var formattedErrorKey = errorKey.replace(/\s+/g, '_')
                .toUpperCase();
            var dictionary = _this.errorsTranslationDictionary;
            if (dictionary.has(formattedErrorKey)) {
                var translations = dictionary.get(formattedErrorKey);
                return (_a = translations[language]) !== null && _a !== void 0 ? _a : translations.EN;
            }
            else {
                console.warn("#TildaIntegrationErrorsService: translation of \"".concat(errorKey, "\" is not found in dictionary"));
                return errorKey;
            }
        };
        this.showError = function ($jform, errorText, exception) { return __awaiter(_this, void 0, void 0, function () {
            var form, breakExecution, errors;
            var _a;
            return __generator(this, function (_b) {
                form = this.jqueryFormResolver.resolve($jform);
                if (!form)
                    return [2 /*return*/];
                breakExecution = false;
                this.onErrorCallbacks.forEach(function (callback) {
                    if (callback(errorText) === false) {
                        breakExecution = true;
                    }
                });
                if (breakExecution)
                    return [2 /*return*/];
                this.currentError = this.getLocatedErrorText(errorText, window.browserLang);
                errors = [
                    {
                        obj: this.tildaFormErroredInputResolver.resolve(form, (_a = exception === null || exception === void 0 ? void 0 : exception.key) !== null && _a !== void 0 ? _a : errorText),
                        type: ['custom'],
                    },
                ];
                this.addErrorElement(form, 'custom', this.currentError);
                window.tildaForm.showErrors(form, errors);
                return [2 /*return*/];
            });
        }); };
        this.addErrorElement = function (form, errorType, errorText) {
            _this.removeErrorElement(form, errorType);
            var firstErrorListElement = form.querySelector('.js-rule-error:first-child');
            var newErrorElement = "<p class=\"t-form__errorbox-item js-rule-error js-rule-error-".concat(errorType, "\">").concat(errorText, "</p>");
            firstErrorListElement === null || firstErrorListElement === void 0 ? void 0 : firstErrorListElement.insertAdjacentHTML('afterend', newErrorElement);
        };
        this.clearError = function ($jform) {
            var form = _this.jqueryFormResolver.resolve($jform);
            if (!form)
                return;
            var errorBox = form.querySelector('.js-errorbox-all');
            if (errorBox) {
                errorBox.style.display = 'none';
            }
            var ruleError = form.querySelector('.js-rule-error');
            if (ruleError) {
                ruleError.style.display = 'none';
            }
            var ruleErrorCustom = form.querySelector('.js-rule-error-custom');
            if (ruleErrorCustom) {
                ruleErrorCustom.innerHTML = '';
            }
        };
        this.onError = function (callback) {
            _this.onErrorCallbacks.push(callback);
        };
        this.onInit();
    }
    ErrorService.prototype.onInit = function () {
        this.watchPageLoad();
    };
    ErrorService.prototype.validateTranslationsArray = function (translations) {
        if (!(translations instanceof Array)) {
            return false;
        }
        return !translations.some(function (item) { return !(item instanceof Array
            && item.length === 2
            && typeof item[0] === 'string'
            && typeof item[1] === 'object'); });
    };
    ErrorService.prototype.getClientValidationError = function (form) {
        var _a;
        var errorMessage = '';
        // t_form_dict - Функция из скрипта tilda-forms, помогает определить ошибку по ключу в нужном переводе
        if (typeof window.t_form_dict === 'function') {
            var errors = window.tildaForm.validate(form);
            // Получаем уникальные значения
            var errorMessages = new Set(errors.map(function (error) { return window.t_form_dict(error.type[0]); }));
            var tildaApiErrorMessage = (_a = document.querySelector('.js-rule-error-all')) === null || _a === void 0 ? void 0 : _a.innerHTML;
            if (tildaApiErrorMessage) {
                errorMessages.add(tildaApiErrorMessage);
            }
            errorMessage = Array.from(errorMessages).join(', ');
        }
        return errorMessage;
    };
    ErrorService.prototype.removeErrorElement = function (form, errorType) {
        var _a;
        (_a = form.querySelector(".js-rule-error-".concat(errorType))) === null || _a === void 0 ? void 0 : _a.remove();
    };
    ErrorService.prototype.resolveError = function (error) {
        if ('error' in error && error.error) {
            var message = error.error;
            if (message.includes('You are already logged in')) {
                return ErrorService.errors.ALREADY_LOGGED_IN || '';
            }
            if (message.includes('The student already has the same education service')) {
                return ErrorService.errors.ALREADY_EXIST_ORDER || '';
            }
            if (message.includes('Логическая ошибка CRM')) {
                return ErrorService.errors.ALREADY_HAS_CORPORATIVE_ACCOUNT || '';
            }
            return error.error;
        }
        return ErrorService.UNKNOWN_ERROR_MESSAGE_RU;
    };
    ErrorService.UNKNOWN_ERROR_MESSAGE_RU = 'Неизвестная ошибка, попробуйте позже';
    ErrorService.errors = (_a = {},
        _a[ESkyengTildaFormsErrorKey.EMAIL_OR_PHONE_ALREADY_EXIST] = 'Эта почта или телефон уже зарегистрированы в ' +
            'одном из продуктов Skyeng',
        _a[ESkyengTildaFormsErrorKey.ALREADY_LOGGED_IN] = 'You are already logged in',
        _a[ESkyengTildaFormsErrorKey.ALREADY_EXIST_ORDER] = 'На вашу почту или телефон уже подана заявка, войдите в ' +
            'личный кабинет',
        _a[ESkyengTildaFormsErrorKey.ALREADY_HAS_CORPORATIVE_ACCOUNT] = "\n        \u0412\u044B \u0443\u043A\u0430\u0437\u0430\u043B\u0438 \u0434\u0430\u043D\u043D\u044B\u0435 \u0441\u043E\u0432\u043F\u0430\u0434\u0430\u044E\u0449\u0438\u0435 \u0441 \u043A\u043E\u0440\u043F\u043E\u0440\u0430\u0442\u0438\u0432\u043D\u044B\u043C \u0430\u043A\u043A\u0430\u0443\u043D\u0442\u043E\u043C.\n        \u041F\u0440\u043E\u0432\u0435\u0440\u044C\u0442\u0435 \u0443\u043A\u0430\u0437\u0430\u043D\u043D\u0443\u044E \u043F\u043E\u0447\u0442\u0443, \u043B\u043E\u0433\u0438\u043D \u0438 \u043F\u0430\u0440\u043E\u043B\u044C \u0432\u043E \u0432\u0445\u043E\u0434\u044F\u0449\u0438\u0445,\n        \u0435\u0441\u043B\u0438 \u043F\u0443\u0441\u0442\u043E \u2014 \u043F\u043E\u0434\u0430\u0439\u0442\u0435 \u0437\u0430\u044F\u0432\u043A\u0443 \u043D\u0430 \u0434\u0440\u0443\u0433\u0438\u0435 \u043A\u043E\u043D\u0442\u0430\u043A\u0442\u044B\n      ",
        _a);
    ErrorService.errorsTranslationDictionaryDefault = new Map([
        [
            ESkyengTildaFormsErrorKey.EMAIL_OR_PHONE_ALREADY_EXIST,
            {
                EN: 'This mail or phone number is already registered in one of Skyeng products',
                RU: 'Эта почта или телефон уже зарегистрированы в одном из продуктов Skyeng',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.ALREADY_LOGGED_IN,
            {
                EN: 'You are already logged in',
                RU: 'Вы уже вошли',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.ALREADY_EXIST_ORDER,
            {
                EN: 'The order has already been submitted to your mail or phone, enter your personal account',
                RU: 'На вашу почту или телефон уже подана заявка, войдите в личный кабинет',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.INCORRECT_PHONE_NUMBER,
            {
                EN: 'Incorrect phone number',
                RU: 'Некорректный номер телефона',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.ERROR,
            {
                EN: 'Oops, something went wrong',
                RU: 'Упс, что-то пошло не так',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.INCORRECT_PHONE_NUMBER_RU,
            {
                EN: 'Incorrect phone number',
                RU: 'Некорректный номер телефона',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.ERROR_RU,
            {
                EN: 'Oops, something went wrong',
                RU: 'Упс, что-то пошло не так',
            },
        ],
        [
            ESkyengTildaFormsErrorKey.UNKNOWN_ERROR,
            {
                EN: 'Unknown error, please try again later',
                RU: ErrorService.UNKNOWN_ERROR_MESSAGE_RU,
            },
        ],
    ]);
    return ErrorService;
}());
export { ErrorService };
