import { PageDataShareService } from '@app/tilda-integration/services/page-data-share.service';
import { CookieService } from '@shared/services/storage/cookie.service';
import { JqueryFormResolver } from '@shared/resolvers/jquery-form.resolver';
import { FormPhoneMaskService } from '@app/tilda-integration/services/form-phone-mask.service';
import { SessionStorageService } from '@shared/services/storage/session-storage.service';
import { TestingService } from '@app/tilda-integration/services/testing-tools/testing.service';
import { FormHelperService } from '@app/tilda-integration/services/form-helper.service';
import { LeadsFormService } from '@app/tilda-integration/services/leads-form.service';
import { AnalyticsWatcherService } from '@app/tilda-integration/services/analytics-watcher.service';
import { FormAnalyticsService } from '@shared/services/form-analytics.service';
import { UrlService } from '@shared/services/url.service';
import { JqueryDataGetResolver } from '@shared/resolvers/jquery-data-get.resolver';
import { JqueryDataSetResolver } from '@shared/resolvers/jquery-data-set.resolver';
import { CommonService } from '@app/tilda-integration/services/common.service';
import { ErrorService } from '@app/tilda-integration/services/error.service';
import { StkFormResolver } from '@shared/resolvers/stk-form.resolver';
import { GeoService } from '@app/tilda-integration/services/geo.service';
import { GeoDataService } from '@shared/services/geo-data.service';
import { DataLayerEventService } from '@shared/services/data-layer-event.service';
import { DebugService } from '@shared/services/testing-tools/debug.service';
import { OrdersFormService } from '@app/tilda-integration/services/orders-form.service';
import { OrderApiService } from './services/order-api.service';
import { PromocodeService } from '@shared/services/promocode.service';
import { TrackHitsService } from '@shared/services/track-hits.service';
import { BaseDomainResolver } from '@shared/resolvers/base-domain.resolver';
import { JqueryParamResolver } from '@shared/resolvers/jquery-param.resolver';
import { TildaFormRepaintEvent } from '@app/tilda-integration/events/tilda-form-repaint.event';
import { FormSuccessHandlerService } from '@app/tilda-integration/services/form-success-handler.service';
import { UserService } from '@app/tilda-integration/services/user.service';
import { AuthService } from '@shared/services/auth.service';
import { TimezoneService } from '@shared/services/timezone.service';
import { FormTimezoneService } from '@app/tilda-integration/services/form-timezone.service';
import { TildaFormJqueryIncludedVersionResolver } from '@shared/resolvers/tilda-form-jquery-included-version.resolver';
import { TildaFormErroredInputResolver } from '@shared/resolvers/tilda-form-errored-input.resolver';
import { TildaFormJqueryDependentWrapResolver } from '@shared/resolvers/tilda-form-jquery-dependent-wrap.resolver';
import { LocaleService } from '@shared/services/locale.service';
import { EsKitFormResolver } from '@shared/resolvers/es-kit-form.resolver';
import '../shared/services/sentry';
var TildaIntegration = /** @class */ (function () {
    function TildaIntegration(tildaFormRepaintEvent) {
        var _this = this;
        this.tildaFormRepaintEvent = tildaFormRepaintEvent;
        this.isRun = false;
        this.run = function () {
            if (_this.isRun)
                return;
            _this.isRun = true;
            var tildaFormJqueryIncludedVersionResolver = new TildaFormJqueryIncludedVersionResolver();
            var tildaFormJqueryDependentWrapResolver = new TildaFormJqueryDependentWrapResolver(tildaFormJqueryIncludedVersionResolver);
            var jqueryDataGetResolver = new JqueryDataGetResolver(tildaFormJqueryDependentWrapResolver);
            var jqueryDataSetResolver = new JqueryDataSetResolver(tildaFormJqueryDependentWrapResolver);
            var jqueryParamResolver = new JqueryParamResolver(tildaFormJqueryDependentWrapResolver);
            var tildaFormErroredInputResolver = new TildaFormErroredInputResolver(tildaFormJqueryIncludedVersionResolver);
            var jqueryFormResolver = new JqueryFormResolver();
            var baseDomainResolver = new BaseDomainResolver();
            var formHelperService = new FormHelperService(jqueryFormResolver);
            var stkResolver = new StkFormResolver(formHelperService);
            var esKitFormResolver = new EsKitFormResolver(formHelperService);
            var cookieService = new CookieService();
            var sessionStorageService = new SessionStorageService();
            var timezoneService = new TimezoneService();
            var formPhoneMaskService = new FormPhoneMaskService(sessionStorageService);
            var testingService = new TestingService(cookieService, formHelperService);
            var commonService = new CommonService();
            var geoDataService = new GeoDataService();
            var geoService = new GeoService(geoDataService);
            var errorService = new ErrorService(jqueryFormResolver, tildaFormErroredInputResolver);
            var debugService = new DebugService(cookieService);
            var dataLayerEventService = new DataLayerEventService(debugService);
            var analyticsService = new FormAnalyticsService(dataLayerEventService);
            var analyticsWatcherService = new AnalyticsWatcherService(commonService, errorService, analyticsService, stkResolver, esKitFormResolver, jqueryDataGetResolver, jqueryDataSetResolver);
            var urlService = new UrlService();
            var orderApiService = new OrderApiService(urlService, baseDomainResolver, jqueryParamResolver);
            var promocodeService = new PromocodeService(cookieService, urlService, jqueryFormResolver, debugService);
            var formTimezoneService = new FormTimezoneService(formHelperService, timezoneService);
            var trackHitsService = new TrackHitsService(cookieService);
            var formSuccessHandlerService = new FormSuccessHandlerService(jqueryFormResolver);
            var authService = new AuthService(baseDomainResolver);
            var userService = new UserService(authService);
            var localeService = new LocaleService();
            /**
             * Init tilda form repaint event
             * */
            _this.tildaFormRepaintEvent.watch();
            /**
             * Common Module
             * Includes common methods for all modules
             */
            window.skyengCommon = {
                alreadyViewedForms: analyticsWatcherService.alreadyViewedForms,
                debug: {
                    _cookieName: DebugService.COOKIE_NAME,
                    isEnabled: debugService.isEnabled,
                    enable: debugService.enable,
                    disable: debugService.disable,
                },
                isJqueryIncludedTildaFormVersion: tildaFormJqueryIncludedVersionResolver.resolve,
                getCookie: function (key) { var _a, _b; return (_b = (_a = cookieService.getItem(key)) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : ''; },
                isElementVisible: commonService.isElementVisible,
                getUrlParam: urlService.getUrlParam,
                getQueryParams: urlService.getQueryParams,
                getFormData: formHelperService.getFormData,
                getValueFromForm: formHelperService.getValueFromForm,
                getClientValidationError: errorService.getClientValidationError,
                resolveJqueryForm: jqueryFormResolver.resolve,
                getCountryLang: geoService.getCountryLang,
                formHelperService: formHelperService,
            };
            /**
             * Unified form submission handler
             */
            window.skyengSuccessHandlers = formSuccessHandlerService;
            window.t396_onSuccess = formSuccessHandlerService.t396_onSuccess;
            /**
             * Test Module
             * Some handlers to make testing easier
             */
            window.skyengTildaTest = testingService;
            /**
             * Orders Forms Module
             * Allows to send orders from tilda forms
             */
            // eslint-disable-next-line @typescript-eslint/no-empty-function
            window.onFormClick = function () { };
            var ordersFormService = new OrdersFormService(commonService, errorService, orderApiService, analyticsWatcherService, formSuccessHandlerService, formHelperService, cookieService, promocodeService, formTimezoneService, trackHitsService, analyticsService, jqueryFormResolver, jqueryDataGetResolver, jqueryDataSetResolver, stkResolver, esKitFormResolver, testingService, localeService);
            window.skyengTildaForms = {
                initAdultFormZeroBlock: ordersFormService.initAdultFormZeroBlock,
                initKidFormZeroBlock: ordersFormService.initKidFormZeroBlock,
                initAdultForm: ordersFormService.initAdultForm,
                initKidForm: ordersFormService.initKidForm,
                initEsKitAdultFormZeroBlock: ordersFormService.initEsKitAdultFormZeroBlock,
                initEsKitKidFormZeroBlock: ordersFormService.initEsKitKidFormZeroBlock,
                initEsKitAdultForm: ordersFormService.initEsKitAdultForm,
                initEsKitKidForm: ordersFormService.initEsKitKidForm,
                _initOrderForm: ordersFormService._initOrderForm,
                onSuccess: ordersFormService.onSuccess,
                _createOrderService: ordersFormService,
                setLocale: ordersFormService.setLocale,
            };
            window.skyengTildaForms.errors = ErrorService.errors;
            window.skyengTildaForms.errorsTranslationDictionaryDefault = ErrorService.errorsTranslationDictionaryDefault;
            window.skyengTildaForms.errorsTranslationDictionary = errorService.errorsTranslationDictionary;
            window.skyengTildaForms._permanentHideSuccessBox = formHelperService._permanentHideSuccessBox;
            window.skyengTildaForms._saveOptionsInElement = formHelperService._saveOptionsInElement;
            window.skyengTildaForms._parsePromoCode = promocodeService.pushPromocodeToInput;
            window.skyengTildaForms._addFormHandlersForSendAnalytics = analyticsWatcherService._addFormHandlersForSendAnalytics;
            window.skyengTildaForms._checkFormShowed = analyticsWatcherService._checkFormShowed;
            window.skyengTildaForms._resolveStkFromForm = stkResolver.resolve;
            window.skyengTildaForms._resolveEsKitFromForm = esKitFormResolver.resolve;
            window.skyengTildaForms._getApiUrl = orderApiService._getApiUrl;
            window.skyengTildaForms._createOrderService = ordersFormService;
            window.skyengTildaForms._analyticsService = {};
            window.skyengTildaForms.validateTranslationsArray = errorService.validateTranslationsArray;
            window.skyengTildaForms.addTranslationsToErrorsTranslationDictionary =
                errorService.addTranslationsToErrorsTranslationDictionary;
            window.skyengTildaForms.getLocatedErrorText = errorService.getLocatedErrorText;
            window.skyengTildaForms.removeErrorElement = errorService.removeErrorElement;
            window.skyengTildaForms.addErrorElement = errorService.addErrorElement;
            window.skyengTildaForms.showError = errorService.showError;
            window.skyengTildaForms.clearError = errorService.clearError;
            window.skyengTildaForms.getSkyengUser = userService.getSkyengUser;
            window.skyengTildaForms.onSkyengUserChange = userService.onSkyengUserChange;
            window.skyengTildaForms.onError = errorService.onError;
            window.skyengTildaForms.showBlock = commonService.showBlock;
            window.skyengTildaForms.hideBlock = commonService.hideBlock;
            window.skyengTildaForms.addGetParamsForApiUrl = orderApiService.addGetParamsForApiUrl;
            window.skyengTildaForms.setNonWritableGetParamKeysForApiUrl = orderApiService.setNonWritableGetParamKeysForApiUrl;
            window.skyengTildaForms.enableParsingGetParamsFromRequestForApiUrls =
                orderApiService.enableParsingGetParamsFromRequestForApiUrls;
            window.skyengTildaForms.setPromoCode = promocodeService.setPromoCode;
            /**
             * Leads Forms Module
             * Allows to send content leads(subscriptions, etc.) from tilda forms
             */
            window.skyengTildaCustomerIntegration = new LeadsFormService(jqueryFormResolver, jqueryDataGetResolver, analyticsWatcherService, formSuccessHandlerService, formHelperService, formTimezoneService, analyticsService, urlService, cookieService, testingService);
            window.skyengTildaCustomerIntegration._addFormHandlersForSendAnalytics =
                analyticsWatcherService._addFormHandlersForSendAnalytics;
            window.skyengTildaCustomerIntegration._checkFormShowed = analyticsWatcherService._checkFormShowed;
            window.skyengTildaCustomerIntegration._analyticsService = {};
            /**
             * Sharing Forms Data Module
             * Allows to fill a form with data passed from the previous page
             */
            window.skyengTildaPageDataShare = new PageDataShareService(jqueryFormResolver, formSuccessHandlerService, formPhoneMaskService, sessionStorageService, cookieService, testingService);
            /**
             * Form Phone Mask Service
             * Contains methods, which are necessary for efficient mask integration with other modules
             */
            window.skyengTildaFormPhonemask = formPhoneMaskService;
        };
    }
    return TildaIntegration;
}());
export { TildaIntegration };
new TildaIntegration(new TildaFormRepaintEvent()).run();
