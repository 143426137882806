var JqueryParamResolver = /** @class */ (function () {
    function JqueryParamResolver(tildaFormJqueryDependentWrapResolver) {
        this.tildaFormJqueryDependentWrapResolver = tildaFormJqueryDependentWrapResolver;
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    JqueryParamResolver.prototype.resolve = function (params) {
        return this.tildaFormJqueryDependentWrapResolver.resolve(function () {
            try {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                var encodedUrl = new URLSearchParams(params);
                return encodedUrl.toString();
            }
            catch (_a) {
                console.warn('Something goes wrong. Query params haven\'t been built');
                return '';
            }
        }, function () { return $.param(params); });
    };
    return JqueryParamResolver;
}());
export { JqueryParamResolver };
