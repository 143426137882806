var TildaFormJqueryIncludedVersionResolver = /** @class */ (function () {
    function TildaFormJqueryIncludedVersionResolver() {
    }
    /* Returned true — means the version of tilda-form lib is jquery included(before new major update) */
    TildaFormJqueryIncludedVersionResolver.prototype.resolve = function () {
        var _a;
        return ((_a = window.tildaForm) === null || _a === void 0 ? void 0 : _a.versionLib)
            ? parseInt(window.tildaForm.versionLib, 10) < 2
            : !!document.querySelector('script[src*="tilda-forms-1."]');
    };
    return TildaFormJqueryIncludedVersionResolver;
}());
export { TildaFormJqueryIncludedVersionResolver };
