var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { StorageService } from 'app/shared/services/storage/storage.service';
import Cookies from 'js-cookie';
var CookieService = /** @class */ (function (_super) {
    __extends(CookieService, _super);
    function CookieService() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    CookieService.prototype.isAvailable = function () {
        return true;
    };
    CookieService.prototype.getAll = function () {
        var items = [];
        for (var _i = 0, _a = Object.entries(Cookies.get()); _i < _a.length; _i++) {
            var _b = _a[_i], key = _b[0], value = _b[1];
            items.push({ key: key, value: value });
        }
        return items;
    };
    CookieService.prototype.getItem = function (key) {
        var value = Cookies.get(key);
        if (!value) {
            return null;
        }
        return { key: key, value: value };
    };
    CookieService.prototype.setItem = function (key, value, options) {
        if (options === void 0) { options = CookieService.DEFAULT_COOKIE_OPTIONS; }
        Cookies.set(key, value, options);
    };
    CookieService.prototype.removeItem = function (key) {
        Cookies.remove(key);
    };
    CookieService.prototype.isExpired = function (expDate) {
        if (!expDate) {
            return true;
        }
        var nowDate = new Date();
        var expireDate = new Date(new Date().getTime() + expDate * 1000);
        return nowDate > expireDate;
    };
    CookieService.DEFAULT_COOKIE_OPTIONS = {
        expires: 7,
        path: '/',
    };
    return CookieService;
}(StorageService));
export { CookieService };
