var UrlService = /** @class */ (function () {
    function UrlService() {
    }
    UrlService.prototype.getUrlParam = function (name) {
        var results = new RegExp("[?&]".concat(name, "=([^&#]*)")).exec(window.location.search);
        if (results === null) {
            return null;
        }
        else {
            return decodeURIComponent(results[1]) || null;
        }
    };
    UrlService.prototype.getQueryParams = function () {
        return this.parseSearchParams(true);
    };
    UrlService.prototype.getQueryParamsObject = function () {
        return this.parseSearchParams(false);
    };
    UrlService.prototype.parseSearchParams = function (keyValueFormat) {
        if (keyValueFormat === void 0) { keyValueFormat = true; }
        var resultKeyValueParams = [];
        var resultParams = {};
        var paramsStrings = window.location.search.replace(/^\?/, '').split('&');
        for (var _i = 0, paramsStrings_1 = paramsStrings; _i < paramsStrings_1.length; _i++) {
            var param = paramsStrings_1[_i];
            var paramChunks = param.split('=');
            if (paramChunks.length !== 2) {
                continue;
            }
            var decodedKey = decodeURIComponent(paramChunks[0]);
            var decodedValue = decodeURIComponent(paramChunks[1]);
            if (keyValueFormat) {
                resultKeyValueParams.push({ key: decodedKey, value: decodedValue });
            }
            else {
                resultParams[decodedKey] = decodedValue;
            }
        }
        return keyValueFormat ? resultKeyValueParams : resultParams;
    };
    UrlService.prototype.queryParamsToString = function (params) {
        if (typeof params !== 'object') {
            return '';
        }
        return Object.keys(params)
            .reduce(function (result, paramKey, index) {
            result += index === 0 ? "".concat(paramKey, "=").concat(params[paramKey]) : "&".concat(paramKey, "=").concat(params[paramKey]);
            return result;
        }, '');
    };
    return UrlService;
}());
export { UrlService };
