import { ESkyengTildaFormState } from '@app/tilda-integration/interfaces/form.interface';
import { SkyengFormTypeEnum } from '@shared/interfaces/form.interface';
import { ErrorService } from '@app/tilda-integration/services/error.service';
import { PromocodeService } from '@shared/services/promocode.service';
import { EFormAnalyticsSentFailEvent, EFormAnalyticsSentSuccessEvent } from '@shared/interfaces/analytics.interface';
import { ESkyengTildaFormsErrorKey } from '@app/tilda-integration/interfaces/error.interface';
import { TildaFormRepaintEvent } from '@app/tilda-integration/events/tilda-form-repaint.event';
/**
 * Orders Forms Module
 * Allows to send orders from tilda forms
 */
var OrdersFormService = /** @class */ (function () {
    function OrdersFormService(commonService, errorService, orderApiService, analyticsWatcherService, formSuccessHandlerService, formHelperService, cookieService, promocodeService, formTimezoneService, trackHitsService, analyticsService, jqueryFormResolver, jqueryDataGetResolver, jqueryDataSetResolver, stkResolver, esKitResolver, testingService, localeService) {
        var _this = this;
        this.commonService = commonService;
        this.errorService = errorService;
        this.orderApiService = orderApiService;
        this.analyticsWatcherService = analyticsWatcherService;
        this.formSuccessHandlerService = formSuccessHandlerService;
        this.formHelperService = formHelperService;
        this.cookieService = cookieService;
        this.promocodeService = promocodeService;
        this.formTimezoneService = formTimezoneService;
        this.trackHitsService = trackHitsService;
        this.analyticsService = analyticsService;
        this.jqueryFormResolver = jqueryFormResolver;
        this.jqueryDataGetResolver = jqueryDataGetResolver;
        this.jqueryDataSetResolver = jqueryDataSetResolver;
        this.stkResolver = stkResolver;
        this.esKitResolver = esKitResolver;
        this.testingService = testingService;
        this.localeService = localeService;
        this.onSuccessCallbacks = [];
        this.alreadyGoToLoginLink = false;
        this.initAdultFormZeroBlock = function (selector, successUrl, options) {
            document.addEventListener(TildaFormRepaintEvent.EVENT_NAME, function () {
                _this.initAdultForm(selector, successUrl, options, true);
            });
            _this.initAdultForm(selector, successUrl, options, true);
        };
        this.initKidFormZeroBlock = function (selector, successUrl, options) {
            document.addEventListener(TildaFormRepaintEvent.EVENT_NAME, function () {
                _this.initKidForm(selector, successUrl, options, true);
            });
            _this.initKidForm(selector, successUrl, options, true);
        };
        this.initEsKitAdultFormZeroBlock = function (selector, successUrl, options) {
            document.addEventListener(TildaFormRepaintEvent.EVENT_NAME, function () {
                _this.initEsKitAdultForm(selector, successUrl, options, true);
            });
            _this.initEsKitAdultForm(selector, successUrl, options, true);
        };
        this.initEsKitKidFormZeroBlock = function (selector, successUrl, options) {
            document.addEventListener(TildaFormRepaintEvent.EVENT_NAME, function () {
                _this.initEsKitKidForm(selector, successUrl, options, true);
            });
            _this.initEsKitKidForm(selector, successUrl, options, true);
        };
        this.initAdultForm = function (selector, successUrl, options, isZeroBlock) {
            _this._initOrderForm(selector, successUrl, options, isZeroBlock, SkyengFormTypeEnum.ADULTS);
        };
        this.initKidForm = function (selector, successUrl, options, isZeroBlock) {
            _this._initOrderForm(selector, successUrl, options, isZeroBlock, SkyengFormTypeEnum.KIDS);
        };
        this.initEsKitAdultForm = function (selector, successUrl, options, isZeroBlock) {
            _this._initOrderForm(selector, successUrl, options, isZeroBlock, SkyengFormTypeEnum.ES_KIT_ADULTS);
        };
        this.initEsKitKidForm = function (selector, successUrl, options, isZeroBlock) {
            _this._initOrderForm(selector, successUrl, options, isZeroBlock, SkyengFormTypeEnum.ES_KIT_KIDS);
        };
        this._initOrderForm = function (formMultipleSelector, successUrl, options, isZeroBlock, formType) {
            var _a;
            var forms = document.querySelectorAll(formMultipleSelector);
            /**
             * formMultipleSelector may be a selector of form wrap elements. In this case
             * nested forms should be found by formMultipleSelector concatenated with DEFAULT_SELECTOR
             * */
            if (formMultipleSelector !== OrdersFormService.DEFAULT_SELECTOR
                && !((_a = forms.item(0)) === null || _a === void 0 ? void 0 : _a.classList.contains(OrdersFormService.DEFAULT_SELECTOR.substr(1)))) {
                forms = document.querySelectorAll(_this.formHelperService
                    .getTildaFormMultipleSelectorFromWrapSelector(formMultipleSelector, OrdersFormService.DEFAULT_SELECTOR));
            }
            forms.forEach(function (form) {
                var _a;
                // this.formHelperService.checkLockingForm(form);
                _this.formHelperService._permanentHideSuccessBox(form);
                _this.formHelperService._saveOptionsInElement(form, options);
                _this.promocodeService.pushPromocodeToInput(form);
                _this.formTimezoneService.addTimezoneInputToForm(form);
                _this.jqueryDataSetResolver.resolve(form, 'skyeng-success-url', successUrl);
                if (isZeroBlock) {
                    form.removeAttribute('success-callback');
                }
                else {
                    _this.jqueryDataSetResolver.resolve(form, 'success-callback', 'window.skyengSuccessHandlers.execute');
                }
                var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top';
                var successHandlerName;
                switch (formType) {
                    case SkyengFormTypeEnum.ADULTS:
                        successHandlerName = 'window.skyengTildaForms._createOrderService.createAdultOrder';
                        break;
                    case SkyengFormTypeEnum.KIDS:
                        successHandlerName = 'window.skyengTildaForms._createOrderService.createKidOrder';
                        break;
                    case SkyengFormTypeEnum.ES_KIT_ADULTS:
                        successHandlerName = 'window.skyengTildaForms._createOrderService.createEsKitAdultOrder';
                        break;
                    case SkyengFormTypeEnum.ES_KIT_KIDS:
                        successHandlerName = 'window.skyengTildaForms._createOrderService.createEsKitKidOrder';
                        break;
                    default:
                        successHandlerName = 'window.skyengTildaForms._createOrderService.createAdultOrder';
                }
                _this.formSuccessHandlerService.addHandler(formId, successHandlerName);
                _this.analyticsWatcherService._addFormHandlersForSendAnalytics('ORDERS', form, formType);
            });
        };
        this.createAdultOrder = function ($form) {
            _this._createOrder($form, SkyengFormTypeEnum.ADULTS);
        };
        this.createKidOrder = function ($form) {
            _this._createOrder($form, SkyengFormTypeEnum.KIDS);
        };
        this.createEsKitAdultOrder = function ($form) {
            _this._createOrder($form, SkyengFormTypeEnum.ES_KIT_ADULTS);
        };
        this.createEsKitKidOrder = function ($form) {
            _this._createOrder($form, SkyengFormTypeEnum.ES_KIT_KIDS);
        };
        this._createOrder = function ($form, formType) {
            var _a;
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            var formId = (_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top';
            var formData = _this.complementFormData(form);
            var formStk = formType === SkyengFormTypeEnum.ADULTS || formType === SkyengFormTypeEnum.KIDS
                ? _this.stkResolver.resolve(form, formType)
                : undefined;
            var esKitFields = formStk === undefined ? _this.esKitResolver.resolve(form) : undefined;
            setTimeout(function () { return _this.formHelperService.restoreFormInputValues(form, formData); });
            _this.formHelperService.setFormState(ESkyengTildaFormState.LOCKED, form, formData);
            _this.orderApiService.create(formType, formData)
                .then(function (data) { return (_this.createOrderSuccessHandler(data, form, formData, formId, formStk, esKitFields)); })
                .catch(function (error) { return (_this.createOrderErrorHandler(form, formData, formId, error, formStk, esKitFields)); });
        };
        this.complementFormData = function (form) {
            var _a, _b;
            var formData = _this.formHelperService.getFormData(form);
            if (_this.promocodeService.defaultPromoCode && formData.get(PromocodeService.INPUT_NAME) === '') {
                formData.set(PromocodeService.INPUT_NAME, _this.promocodeService.defaultPromoCode);
            }
            var successUrl = _this.jqueryDataGetResolver.resolve(form, 'skyeng-success-url');
            formData.set('generateLoginLinkTo', successUrl !== null && successUrl !== void 0 ? successUrl : '');
            var lastHitId = _this.trackHitsService.getLastHitId();
            if (lastHitId) {
                formData.set('hitId', lastHitId);
            }
            var inviterHashCookie = _this.cookieService.getItem('inviter_hash');
            if (inviterHashCookie) {
                formData.set('inviterHash', inviterHashCookie.value);
            }
            formData.set('locale', _this.localeService.getAvailableLocale(_this.locale));
            formData.set('serviceLocale', _this.localeService.getAvailableServiceLocale((_a = _this.serviceLocale) !== null && _a !== void 0 ? _a : 'ru'));
            formData.set('uri', "".concat(window.location.origin).concat(window.location.pathname));
            formData.set('user_agent', (_b = window.navigator) === null || _b === void 0 ? void 0 : _b.userAgent);
            return formData;
        };
        this.createOrderSuccessHandler = function (data, form, formData, formId, formStk, esKitFields) {
            if ('error' in data) {
                _this.createOrderErrorHandler(form, formData, formId, data, formStk, esKitFields, data.exception);
                return;
            }
            if ('userLogIn' in data) {
                _this.analyticsService.sendSuccessEvent(EFormAnalyticsSentSuccessEvent.ORDERS, formId, formStk, esKitFields, !data.loginLink, undefined, data.userId);
                _this.createOrderSuccessHandlerCallback(data, form, formData);
            }
            else {
                _this.formHelperService.setFormState(ESkyengTildaFormState.FILLING, form, formData);
            }
        };
        this.createOrderSuccessHandlerCallback = function (data, form, formData) {
            var _a;
            _this.formHelperService.setFormState(ESkyengTildaFormState.FILLING, form, formData);
            /**
             * After major scripts update(tilda-forms from 1.* to 2.*)
             * hide/show functionality works incorrectly with blocks which includes images(not displayed).
             * Triggering a force repainting of blocks solve this problem
             * */
            setTimeout(_this.commonService.triggerTildaBlocksRepaint);
            if (data.userLogIn) {
                if (_this.alreadyGoToLoginLink) {
                    return;
                }
                _this.alreadyGoToLoginLink = true;
                var breakExecution_1 = false;
                _this.onSuccessCallbacks.forEach(function (callback) {
                    if (callback(data) === false) {
                        breakExecution_1 = true;
                    }
                });
                if (breakExecution_1) {
                    return;
                }
                if (typeof data.loginLink === 'string') {
                    _this.redirectToSuccessUrl(data.loginLink);
                }
                else if (_this.jqueryDataGetResolver.resolve(form, 'skyeng-success-url')) {
                    _this.redirectToSuccessUrl(_this.jqueryDataGetResolver.resolve(form, 'skyeng-success-url'));
                }
                else {
                    console.warn('#TildaIntegrationOrdersService: no login redirect link is found');
                }
            }
            else if (data.userId
                && (('educationServiceId' in data && data.educationServiceId) || ('esKitId' in data && data.esKitId))) {
                void _this.errorService.showError(form, (_a = ErrorService.errors[ESkyengTildaFormsErrorKey.ALREADY_EXIST_ORDER]) !== null && _a !== void 0 ? _a : '');
            }
            else {
                void _this.errorService.showError(form, ErrorService.UNKNOWN_ERROR_MESSAGE_RU);
            }
        };
        this.redirectToSuccessUrl = function (successUrl) {
            if (!successUrl
                || (_this.testingService.isEnabled()
                    && !confirm("Redirect to ".concat(successUrl, ". Continue?"))))
                return;
            document.location = successUrl;
        };
        this.createOrderErrorHandler = function (form, formData, formId, error, formStk, esKitFields, exception) {
            /** Для показа ошибок валидации мы используем встроенное в тильду событие tildaform:aftererror.
             * Ошибку с бэка мы так же показываем с помощью метода тильды, который вызывает это же событие.
             * Из-за этого при ошибке с бэка мы 2 раза отправляли *_sent_fail событие.
             * Поэтому юзаем такой флаг, чтобы отправлять это событие только единожды.
             */
            var errorMessage = _this.errorService.resolveError(error);
            _this.jqueryDataSetResolver.resolve(form, 'is-backend-validation-error', '1');
            void _this.errorService.showError(form, errorMessage, exception);
            _this.formHelperService.setFormState(ESkyengTildaFormState.FILLING, form, formData);
            _this.analyticsService.sendFailEvent(EFormAnalyticsSentFailEvent.ORDERS, formId, errorMessage, formStk, esKitFields);
        };
        this.onSuccess = function (callback) {
            _this.onSuccessCallbacks.push(callback);
        };
        this.setLocale = function (locales) {
            _this.locale = locales === null || locales === void 0 ? void 0 : locales.locale;
            _this.serviceLocale = locales === null || locales === void 0 ? void 0 : locales.serviceLocale;
        };
    }
    OrdersFormService.DEFAULT_SELECTOR = '.js-form-proccess';
    return OrdersFormService;
}());
export { OrdersFormService };
