var CommonService = /** @class */ (function () {
    function CommonService() {
    }
    CommonService.prototype.isElementVisible = function (selector) {
        var element = document.querySelector(selector);
        if (!element) {
            return false;
        }
        var elementTop = element.getBoundingClientRect().top;
        var elementBottom = element.getBoundingClientRect().bottom;
        var elementHalf = elementTop + Math.ceil((elementBottom - elementTop) / 2);
        var windowHeight = window.innerHeight;
        if (elementHalf === 0) {
            return false;
        }
        return elementHalf < windowHeight;
    };
    CommonService.prototype.showBlock = function (selector) {
        var elements = document.querySelectorAll(selector);
        elements.forEach(function (element) {
            if (element) {
                element.style.setProperty('display', 'block', 'important');
            }
        });
    };
    CommonService.prototype.hideBlock = function (selector) {
        var elements = document.querySelectorAll(selector);
        elements.forEach(function (element) {
            if (element) {
                element.style.setProperty('display', 'none', 'important');
            }
        });
    };
    CommonService.prototype.triggerTildaBlocksRepaint = function () {
        window.dispatchEvent(new Event('resize'));
    };
    return CommonService;
}());
export { CommonService };
