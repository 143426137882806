/**
 * Unified form submission handler
 */
var FormSuccessHandlerService = /** @class */ (function () {
    function FormSuccessHandlerService(jqueryFormResolver) {
        var _this = this;
        this.jqueryFormResolver = jqueryFormResolver;
        this._handlersByForms = {};
        this.addHandler = function (formId, handlerName) {
            if (!_this._handlersByForms[formId]) {
                _this._handlersByForms[formId] = [];
            }
            if (!_this._handlersByForms[formId].includes(handlerName)) {
                _this._handlersByForms[formId].push(handlerName);
            }
        };
        this.execute = function ($form) {
            var form = _this.jqueryFormResolver.resolve($form);
            var formId = form === null || form === void 0 ? void 0 : form.getAttribute('id');
            if (!formId)
                return;
            if (!_this._handlersByForms[formId])
                return;
            for (var _i = 0, _a = _this._handlersByForms[formId]; _i < _a.length; _i++) {
                var successCallback = _a[_i];
                // eslint-disable-next-line no-eval
                eval(successCallback + '($form)');
            }
        };
        /* Read more about function here https://help.tilda.cc/tips/javascript#rec116681236 */
        this.t396_onSuccess = function ($form) {
            var form = _this.jqueryFormResolver.resolve($form);
            if (!form)
                return;
            _this.execute(form);
        };
    }
    return FormSuccessHandlerService;
}());
export { FormSuccessHandlerService };
