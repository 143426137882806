import { SkyengFormTypeEnum } from '@shared/interfaces/form.interface';
import { EServiceTypeKey } from '@shared/interfaces/stk.enum';
import { EProductSubject } from '@shared/interfaces/product-subject.enum';
var StkFormResolver = /** @class */ (function () {
    function StkFormResolver(formHelperService) {
        var _this = this;
        this.formHelperService = formHelperService;
        this.resolve = function (form, formType, stkFieldName) {
            if (stkFieldName === void 0) { stkFieldName = StkFormResolver.defaultStkFieldName; }
            if (!form)
                return StkFormResolver.defaultReturnValue;
            var stk = _this.formHelperService.getValueAsStringFromForm(form, stkFieldName);
            if (stk) {
                return stk;
            }
            if (formType === SkyengFormTypeEnum.ADULTS) {
                return EServiceTypeKey.ENGLISH_ADULT_NOT_NATIVE_SPEAKER;
            }
            if (formType === SkyengFormTypeEnum.KIDS) {
                var subject = _this.formHelperService.getValueAsStringFromForm(form, StkFormResolver.defaultSubjectFieldName);
                if (subject === EProductSubject.MATHEMATICS) {
                    return EServiceTypeKey.MATHEMATICS_KIDS;
                }
                if (subject === EProductSubject.ENGLISH) {
                    var age = _this.formHelperService.getValueAsStringFromForm(form, StkFormResolver.defaultChildAgeFieldName);
                    return !age || parseInt(age, 10) > 9
                        ? EServiceTypeKey.ENGLISH_JUNIOR_NOT_NATIVE_SPEAKER
                        : EServiceTypeKey.ENGLISH_KLP_NOT_NATIVE_SPEAKER;
                }
            }
            return StkFormResolver.defaultReturnValue;
        };
    }
    StkFormResolver.defaultStkFieldName = 'serviceTypeKey';
    StkFormResolver.defaultSubjectFieldName = 'subject';
    StkFormResolver.defaultChildAgeFieldName = 'childAge';
    StkFormResolver.defaultReturnValue = 'unknown';
    return StkFormResolver;
}());
export { StkFormResolver };
