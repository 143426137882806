var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EnalableModeService } from '@shared/services/testing-tools/enalable-mode.service';
var DebugService = /** @class */ (function (_super) {
    __extends(DebugService, _super);
    function DebugService(cookieService) {
        var _this = _super.call(this, cookieService, DebugService.COOKIE_NAME) || this;
        _this.cookieService = cookieService;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this.log = function () {
            var messages = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                messages[_i] = arguments[_i];
            }
            if (!_this.isEnabled())
                return;
            console.info.apply(console, messages);
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this.warn = function () {
            var messages = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                messages[_i] = arguments[_i];
            }
            if (!_this.isEnabled())
                return;
            console.warn.apply(console, messages);
        };
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _this.error = function () {
            var messages = [];
            for (var _i = 0; _i < arguments.length; _i++) {
                messages[_i] = arguments[_i];
            }
            if (!_this.isEnabled())
                return;
            console.error.apply(console, messages);
        };
        return _this;
    }
    DebugService.COOKIE_NAME = 'debugMode';
    return DebugService;
}(EnalableModeService));
export { DebugService };
