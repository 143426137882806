/**
 * Form Phone Mask Service
 * Contains methods, which are necessary for efficient mask integration with other modules
 */
var FormPhoneMaskService = /** @class */ (function () {
    function FormPhoneMaskService(sessionStorageService) {
        var _this = this;
        this.sessionStorageService = sessionStorageService;
        this.intervalPassed = 0;
        this.scrolledToTopOnce = false;
        this.watchLoading = function () {
            _this.interval = window.setInterval(_this.setProxying, 50);
        };
        this.setProxying = function () {
            var successfullySetCount = 0;
            successfullySetCount += _this.proxyingInitPhoneMaskFormFn() ? 1 : 0;
            successfullySetCount += _this.proxyingChooseCountryFn() ? 1 : 0;
            if (successfullySetCount === 2) {
                clearInterval(_this.interval);
                _this.updateInputsWithCountryCode();
            }
            if (_this.intervalPassed > 3000) {
                clearInterval(_this.interval);
                console.warn('ReSubstitution of country code will ' +
                    'not work. Make sure "tilda-form-mask-*.js" module is included');
            }
            _this.intervalPassed = _this.intervalPassed + 50;
        };
        this.onInit();
    }
    FormPhoneMaskService.prototype.onInit = function () {
        this.watchLoading();
    };
    /**
     * Proxying original "t_form_phonemask_load" function
     * to add reSubstitution of country code functionality
     */
    FormPhoneMaskService.prototype.proxyingInitPhoneMaskFormFn = function () {
        var _this = this;
        if (window.t_form_phonemask_initPhoneMaskForm_original)
            return true;
        if (typeof window.t_form_phonemask_initPhoneMaskForm === 'function') {
            if (!window.t_form_phonemask_initPhoneMaskForm_original) {
                window.t_form_phonemask_initPhoneMaskForm_original = window.t_form_phonemask_initPhoneMaskForm;
            }
            window.t_form_phonemask_initPhoneMaskForm = function (e, n, isoCode) {
                var _a, _b;
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return window.t_form_phonemask_initPhoneMaskForm_original(e, n, (_b = (_a = _this.sessionStorageService.getItem('phonemaskMaskCountry')) === null || _a === void 0 ? void 0 : _a.value) !== null && _b !== void 0 ? _b : isoCode);
            };
            return true;
        }
        return false;
    };
    /**
     * Proxying original "t_form_phonemask__chooseCountry" function
     * to add reSubstitution of country code functionality
     */
    FormPhoneMaskService.prototype.proxyingChooseCountryFn = function () {
        var _this = this;
        if (window.t_form_phonemask__chooseCountry_original)
            return true;
        if (typeof window.t_form_phonemask__chooseCountry === 'function') {
            if (!window.t_form_phonemask__chooseCountry_original) {
                window.t_form_phonemask__chooseCountry_original = window.t_form_phonemask__chooseCountry;
            }
            window.t_form_phonemask__chooseCountry = function (inputGroup, option) {
                var _a;
                _this.sessionStorageService.setItem('phonemaskMaskCountry', (_a = option === null || option === void 0 ? void 0 : option.getAttribute('data-phonemask-country-code')) !== null && _a !== void 0 ? _a : '');
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                return window.t_form_phonemask__chooseCountry_original(inputGroup, option);
            };
            return true;
        }
        return false;
    };
    FormPhoneMaskService.prototype.addMaskDataByMaskInputToFormData = function (maskInput, formData) {
        var _a, _b, _c, _d, _e;
        var phoneInput = (_a = maskInput.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector('input.js-phonemask-result');
        var phoneInputName = phoneInput === null || phoneInput === void 0 ? void 0 : phoneInput.getAttribute('name');
        var phoneInputValue = phoneInput === null || phoneInput === void 0 ? void 0 : phoneInput.value;
        var phoneCode = (_b = maskInput.getAttribute('data-phonemask-code')) !== null && _b !== void 0 ? _b : '';
        var phoneCountryCode = (_e = (_d = (_c = maskInput.parentElement) === null || _c === void 0 ? void 0 : _c.querySelector('.t-input-phonemask__select [data-phonemask-flag]')) === null || _d === void 0 ? void 0 : _d.getAttribute('data-phonemask-flag')) !== null && _e !== void 0 ? _e : '';
        if (phoneInputName && phoneInputValue) {
            formData[phoneInputName] = phoneInputValue;
            formData[phoneInputName + FormPhoneMaskService.CODE_SUFFIX] = phoneCode;
            formData[phoneInputName + FormPhoneMaskService.WITHOUT_CODE_SUFFIX] = maskInput.value;
            formData[phoneInputName + FormPhoneMaskService.COUNTRY_CODE_SUFFIX] = phoneCountryCode;
        }
    };
    FormPhoneMaskService.prototype.updateInputsWithCountryCode = function (code) {
        var _a;
        var countryCode = code !== null && code !== void 0 ? code : (_a = this.sessionStorageService.getItem('phonemaskMaskCountry')) === null || _a === void 0 ? void 0 : _a.value;
        console.info('serviceCountryCode', countryCode);
        if (!countryCode)
            return;
        var inputsBeforeMaskScriptLoaded = document.querySelectorAll('.js-phonemask-input');
        var inputsAfterMaskScriptLoaded = document.querySelectorAll('.js-phonemask-result');
        inputsBeforeMaskScriptLoaded.forEach(function (input) {
            input.setAttribute('data-phonemask-maskcountry', countryCode);
        });
        Array.from(inputsAfterMaskScriptLoaded).reverse().forEach(function (input) {
            var _a, _b;
            window.t_form_phonemask__chooseCountry(input.closest('.t-input-group'), (_b = (_a = input.parentElement) === null || _a === void 0 ? void 0 : _a.querySelector(".t-input-phonemask__options-wrap [data-phonemask-country-code=\"".concat(countryCode, "\"]"))) !== null && _b !== void 0 ? _b : null);
        });
        if (!this.scrolledToTopOnce) {
            window.scrollTo({ top: 0 });
            this.scrolledToTopOnce = true;
        }
    };
    FormPhoneMaskService.CODE_SUFFIX = 'Code';
    FormPhoneMaskService.WITHOUT_CODE_SUFFIX = 'WithoutCode';
    FormPhoneMaskService.COUNTRY_CODE_SUFFIX = 'CountryCode';
    FormPhoneMaskService.SESSION_COUNTRY_CODE_NAME = 'phonemaskMaskCountry';
    return FormPhoneMaskService;
}());
export { FormPhoneMaskService };
