import { CRM_EMAIL_RELATED_ERROR_KEYS, CRM_NAME_RELATED_ERROR_KEYS, CRM_PHONE_RELATED_ERROR_KEYS, } from '@shared/consts/crm-error-key.const';
var TildaFormErroredInputResolver = /** @class */ (function () {
    function TildaFormErroredInputResolver(tildaFormJqueryIncludedVersionResolver) {
        this.tildaFormJqueryIncludedVersionResolver = tildaFormJqueryIncludedVersionResolver;
        this.dummyElement = document.createElement('div');
    }
    TildaFormErroredInputResolver.prototype.resolve = function (form, errorKey) {
        if (!form)
            return this.dummyElement;
        var erroredInput = this.resolveFormInputByErrorKey(form, errorKey);
        if (this.tildaFormJqueryIncludedVersionResolver.resolve()) {
            erroredInput = typeof $ === 'function' && typeof window.jQuery === 'function' ? $(erroredInput) : 'none';
        }
        return erroredInput;
    };
    TildaFormErroredInputResolver.prototype.resolveFormInputByErrorKey = function (form, errorKey) {
        var targetInputSelectorForHighlighting = '';
        switch (true) {
            case CRM_NAME_RELATED_ERROR_KEYS.includes(errorKey):
                targetInputSelectorForHighlighting = 'input[name*="name" i]';
                break;
            case CRM_EMAIL_RELATED_ERROR_KEYS.includes(errorKey):
                targetInputSelectorForHighlighting = 'input[name*="email" i]';
                break;
            case CRM_PHONE_RELATED_ERROR_KEYS.includes(errorKey):
                targetInputSelectorForHighlighting = 'input[name*="phone" i]';
                break;
            default:
                break;
        }
        return this.getSuitableElementForHighlighting(form, targetInputSelectorForHighlighting);
    };
    TildaFormErroredInputResolver.prototype.getSuitableElementForHighlighting = function (form, targetInputSelector) {
        /**
         * Returning dummy element means there isn't any suitable element to highlight,
         * an error text will be displayed only in the common popup
         * */
        if (!targetInputSelector)
            return this.dummyElement;
        var targetInputs = form.querySelectorAll(targetInputSelector);
        /**
         * If the target inputs are greater than 1, it's not clear
         * which one triggered the crm validator
         * */
        if (targetInputs.length === 1)
            return targetInputs.item(0);
        return this.dummyElement;
    };
    return TildaFormErroredInputResolver;
}());
export { TildaFormErroredInputResolver };
