var TildaFormJqueryDependentWrapResolver = /** @class */ (function () {
    function TildaFormJqueryDependentWrapResolver(tildaFormJqueryIncludedVersionResolver) {
        this.tildaFormJqueryIncludedVersionResolver = tildaFormJqueryIncludedVersionResolver;
    }
    TildaFormJqueryDependentWrapResolver.prototype.resolve = function (jqueryAbsentBranchCallback, jqueryIncludedBranchCallback) {
        var isJqueryIncludedTildaFormVersion = this.tildaFormJqueryIncludedVersionResolver.resolve();
        var isJqueryDeclared = typeof $ === 'function' && typeof window.jQuery === 'function';
        if (isJqueryIncludedTildaFormVersion && !isJqueryDeclared) {
            console.warn('Jquery is not defined. The app may work incorrectly. ' +
                'Make sure you have included the jquery script in your project');
        }
        if (!isJqueryIncludedTildaFormVersion || !isJqueryDeclared) {
            return jqueryAbsentBranchCallback();
        }
        return jqueryIncludedBranchCallback();
    };
    return TildaFormJqueryDependentWrapResolver;
}());
export { TildaFormJqueryDependentWrapResolver };
