var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
import { EnalableModeService } from '@shared/services/testing-tools/enalable-mode.service';
/**
 * Test Module
 * Some handlers to make testing easier
 */
var TestingService = /** @class */ (function (_super) {
    __extends(TestingService, _super);
    function TestingService(cookieService, formHelperService) {
        var _this = _super.call(this, cookieService, TestingService.COOKIE_NAME) || this;
        _this.cookieService = cookieService;
        _this.formHelperService = formHelperService;
        _this.testBumpData = [
            { key: 'name', value: 'tstprod' },
            { key: 'parentName', value: 'tstprod' },
            { key: 'childName', value: 'tstprod' },
            { key: 'email', value: 'tstprod-email-bump@mailinator.com' },
            { key: 'parentEmail', value: 'tstprod-email-bump@mailinator.com' },
            { key: 'phone', value: '+7 (922) 199-67-64' },
            { key: 'parentPhone', value: '+7 (922) 199-67-64' },
            { key: 'tildaspec-phone-part[]', value: '(922) 199-67-64' },
        ];
        _this.testFormData = _this.getTestData();
        _this.testBumpFormData = _this.getTestData(true);
        return _this;
    }
    TestingService.prototype.getTestData = function (isBump) {
        if (isBump === void 0) { isBump = false; }
        var formData = new FormData();
        this.testBumpData.forEach(function (item) {
            formData.append(item.key, !isBump && item.key.toLowerCase().includes('email')
                ? item.value.replace('bump', (Math.random() + 1).toString(36).substring(2))
                : item.value);
        });
        return formData;
    };
    TestingService.prototype.fillForms = function (isBump, selector) {
        var _this = this;
        if (isBump === void 0) { isBump = false; }
        if (selector === void 0) { selector = 'form'; }
        var forms = document.querySelectorAll(selector);
        forms.forEach(function (form) {
            _this.formHelperService
                .restoreFormInputValues(form, isBump ? _this.testBumpFormData : _this.testFormData);
        });
    };
    TestingService.COOKIE_NAME = 'tildaIntegrationTestMode';
    return TestingService;
}(EnalableModeService));
export { TestingService };
