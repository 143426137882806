import { FormHelperService } from '@app/tilda-integration/services/form-helper.service';
var FormTimezoneService = /** @class */ (function () {
    function FormTimezoneService(formHelperService, timezoneService) {
        var _this = this;
        this.formHelperService = formHelperService;
        this.timezoneService = timezoneService;
        this.addTimezoneInputToForm = function (form) {
            _this.formHelperService.addInputToForm(form, "<input type=\"hidden\" name=\"".concat(FormTimezoneService.ATTRIBUTE_NAME, "\"") +
                " value=\"".concat(_this.timezoneService.getTimezone(), "\">"), FormTimezoneService.ATTRIBUTE_NAME, FormHelperService.INPUT_BOX_SELECTOR);
        };
    }
    FormTimezoneService.ATTRIBUTE_NAME = 'timezone';
    return FormTimezoneService;
}());
export { FormTimezoneService };
