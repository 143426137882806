var TrackHitsService = /** @class */ (function () {
    function TrackHitsService(cookieService) {
        var _this = this;
        this.cookieService = cookieService;
        this.getSeidCookie = function () {
            var storedItem = _this.cookieService.getItem(TrackHitsService.SEID_COOKIE_NAME);
            return storedItem ? storedItem.value : '';
        };
    }
    TrackHitsService.prototype.getLastHitId = function () {
        var _a;
        if (typeof ((_a = window.skyengTrackHits) === null || _a === void 0 ? void 0 : _a.get_current_hit_id) === 'function') {
            return window.skyengTrackHits.get_current_hit_id();
        }
        return null;
    };
    TrackHitsService.SEID_COOKIE_NAME = '_seid';
    return TrackHitsService;
}());
export { TrackHitsService };
