import { FormPhoneMaskService } from '@app/tilda-integration/services/form-phone-mask.service';
/**
 * Sharing Forms Data Module
 * Allows to fill a form with data passed from the previous page
 */
var PageDataShareService = /** @class */ (function () {
    function PageDataShareService(jqueryFormResolver, formSuccessHandlerService, formPhoneMaskService, sessionStorageService, cookieService, testingService) {
        var _this = this;
        this.jqueryFormResolver = jqueryFormResolver;
        this.formSuccessHandlerService = formSuccessHandlerService;
        this.formPhoneMaskService = formPhoneMaskService;
        this.sessionStorageService = sessionStorageService;
        this.cookieService = cookieService;
        this.testingService = testingService;
        this.fieldsMap = {
            'first_name': 'parentName',
            'name': 'parentName',
            'email': 'parentEmail',
            'phone': 'parentPhone',
        };
        this.saveData = function (fromFormWrapperSelector) {
            setTimeout(function () {
                var forms = document.querySelectorAll(fromFormWrapperSelector + ' form');
                if (forms.length < 1) {
                    console.warn('#TildaIntegrationPageDataShareService: no form is found');
                    return;
                }
                _this.addHandlersOnFormSubmitSuccessEvent(forms);
            }, 1000);
        };
        this.addHandlersOnFormSubmitSuccessEvent = function (forms) {
            forms.forEach(function (form) {
                var _a;
                _this.formSuccessHandlerService.addHandler((_a = form.getAttribute('id')) !== null && _a !== void 0 ? _a : 'top', 'window.skyengTildaPageDataShare.formSuccessHandler');
            });
        };
        this.formSuccessHandler = function ($jform) {
            var form = _this.jqueryFormResolver.resolve($jform);
            if (!form)
                return;
            var inputs = form.querySelectorAll('input:not([type="hidden"])');
            var formData = _this.getFormDataFromInputs(inputs);
            _this.cookieService.setItem('sharedPageData', JSON.stringify(formData));
        };
        this.getFormDataFromInputs = function (inputs) {
            var formData = {};
            inputs.forEach(function (input) {
                var attributeName = input.getAttribute('name');
                /* special handler for tilda-phone-mask */
                if (attributeName === 'tildaspec-phone-part[]') {
                    _this.formPhoneMaskService.addMaskDataByMaskInputToFormData(input, formData);
                }
                if (attributeName) {
                    formData[attributeName] = input.value;
                }
            });
            return formData;
        };
        this.getData = function (toFormWrapperSelector) {
            var _a;
            var sharedPageDataStorageItem = _this.cookieService.getItem('sharedPageData');
            var decodedFormData = (_a = new URLSearchParams(window.location.search).get('sharedData')) !== null && _a !== void 0 ? _a : '';
            if (!(sharedPageDataStorageItem === null || sharedPageDataStorageItem === void 0 ? void 0 : sharedPageDataStorageItem.value)) {
                console.warn('#TildaIntegrationPageDataShareService: sharedPageData cookie is not found or empty');
            }
            if (!decodedFormData.length) {
                console.warn('#TildaIntegrationPageDataShareService: sharedPageData url params is not found or empty');
            }
            else {
                var formDataFromUrl = JSON.parse(decodedFormData);
                formDataFromUrl['tildaspec-phone-part[]'] = formDataFromUrl.phoneWithoutCode;
                decodedFormData = JSON.stringify(formDataFromUrl);
            }
            if (!decodedFormData.length && !(sharedPageDataStorageItem === null || sharedPageDataStorageItem === void 0 ? void 0 : sharedPageDataStorageItem.value)) {
                return;
            }
            var sharedPageData = (sharedPageDataStorageItem === null || sharedPageDataStorageItem === void 0 ? void 0 : sharedPageDataStorageItem.value)
                ? JSON.parse(sharedPageDataStorageItem.value)
                : JSON.parse(decodedFormData);
            if (document.querySelectorAll("".concat(toFormWrapperSelector, " .t396")).length > 0) {
                setTimeout(function () {
                    _this.loadDataToForm(toFormWrapperSelector, sharedPageData);
                }, 2000);
            }
            else {
                _this.loadDataToForm(toFormWrapperSelector, sharedPageData);
            }
        };
        this.loadDataToForm = function (toFormWrapperSelector, sharedPageData) {
            var _loop_1 = function (key, value) {
                var formattedKey = key;
                if (key in _this.fieldsMap) {
                    formattedKey = _this.fieldsMap[key];
                }
                var inputs = document
                    .querySelectorAll("".concat(toFormWrapperSelector, " form input[name=\"").concat(formattedKey, "\"]"));
                Array.from(inputs).reverse().forEach(function (input) {
                    /* special handler for tilda-phone-mask */
                    if (key.toLowerCase().includes('phone') && input.classList.contains('js-phonemask-input')) {
                        /**
                         * Case 1. Tilda-phone-mask lib doesn't init completely,
                         * then put code to sessionStorage and set phone value without code
                         * */
                        _this.sessionStorageService.setItem(FormPhoneMaskService.SESSION_COUNTRY_CODE_NAME, sharedPageData[key + FormPhoneMaskService.COUNTRY_CODE_SUFFIX]);
                        input.value = sharedPageData[key + FormPhoneMaskService.WITHOUT_CODE_SUFFIX];
                        input.blur();
                        return;
                    }
                    else if (key.toLowerCase().includes('phone') && input.classList.contains('js-phonemask-result')
                        && typeof window.t_form_phonemask__chooseCountry === 'function') {
                        /**
                         * Case 2. Tilda-phone-mask lib inits completely,
                         * then put code to sessionStorage and set full phone value with code
                         * */
                        _this.sessionStorageService.setItem(FormPhoneMaskService.SESSION_COUNTRY_CODE_NAME, sharedPageData[key + FormPhoneMaskService.COUNTRY_CODE_SUFFIX]);
                    }
                    input.value = value;
                    input.blur();
                });
                _this.formPhoneMaskService.updateInputsWithCountryCode();
            };
            for (var _i = 0, _a = Object.entries(sharedPageData); _i < _a.length; _i++) {
                var _b = _a[_i], key = _b[0], value = _b[1];
                _loop_1(key, value);
            }
            if (_this.testingService.isEnabled()) {
                /**
                 * Don't remove shared page data from cookie storage
                 * allowing developer to avoid resending form
                 * */
                return;
            }
            _this.cookieService.removeItem('sharedPageData');
        };
    }
    return PageDataShareService;
}());
export { PageDataShareService };
